import React, { RefObject } from 'react'

import { StyledDomainsContainer as S } from './DomainsContainer.styles'

type TProps = {
  containerRef: RefObject<HTMLDivElement>
  children: React.ReactNode
}

export const DomainsContainer: React.FC<TProps> = ({
  children,
  containerRef,
}) => <S.DomainsContainer ref={containerRef}>{children}</S.DomainsContainer>
