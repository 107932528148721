import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { BANNER_LOGOS } from 'components/Header/constants'

import { getAutologinTokenAction } from 'root-redux/actions/user'
import { selectAppName } from 'root-redux/selects/common'
import { selectAutologinToken, selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { getLoginMethodFromLocalStorage } from 'helpers/getLoginMethodFromLocalStorage'

import { eventLogger } from 'services/eventLogger.service'

import {
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  LoginMethod,
  OPEN_APP_LINKS,
} from 'root-constants'

import { StyledDownloadBanner as S } from './DownloadBanner.styles'

export const DownloadBanner: React.FC = () => {
  const { t } = useTranslation()
  const appName = useSelector(selectAppName)
  const autologinToken = useSelector(selectAutologinToken)
  const userId = useSelector(selectUUID)

  const loginMethod = getLoginMethodFromLocalStorage()

  const dispatch: TAppDispatch = useDispatch()

  const downloadAppLink = useMemo(() => {
    const oneLink =
      OPEN_APP_LINKS[appName][loginMethod] ||
      OPEN_APP_LINKS[appName][LoginMethod.EMAIL]

    return oneLink.replace(CUSTOM_TOKEN_LOCAL_STORAGE_KEY, autologinToken)
  }, [appName, loginMethod, autologinToken])

  useEffect(() => {
    dispatch(getAutologinTokenAction())
  }, [appName, dispatch, userId])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink

    eventLogger.logOpenApp(userId)
  }, [downloadAppLink, userId])

  return (
    <S.Wrapper color={BANNER_LOGOS[appName].bgColor}>
      <S.LogoContainer>
        <img src={BANNER_LOGOS[appName].image} alt="logo" />
        <div>
          <S.Logo>{BANNER_LOGOS[appName].name}</S.Logo>
          <S.OpenIn>
            <Trans i18nKey="openIn" context={appName} />
          </S.OpenIn>
        </div>
      </S.LogoContainer>

      <S.Button data-deep-link={downloadAppLink} onClick={handleContinue}>
        {t('button.open')}
      </S.Button>
    </S.Wrapper>
  )
}
