import { IRequestOptions, IResponseResult } from 'models/api.model'
import {
  ICustomerInfoRaw,
  IGetUserSubscriptionsInfoRaw,
} from 'models/commonApi.model'

import { ApiService } from 'services/api.service'

import { RequestMethod } from 'root-constants'

export class CommonApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getUserSubscriptionsInfo(
    token: string,
    appName: string,
  ): Promise<IResponseResult<IGetUserSubscriptionsInfoRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: {
        token,
        app_name: appName,
      },
    }

    return this.api.makeRequest<IGetUserSubscriptionsInfoRaw>(
      `v1/subscriptions`,
      options,
    )
  }

  getCustomerInfo(
    token: string,
    appName: string,
  ): Promise<IResponseResult<ICustomerInfoRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: {
        token,
        app_name: appName,
      },
    }

    return this.api.makeRequest<ICustomerInfoRaw>(`v1/customers`, options)
  }

  unsubscribeByToken({
    subscriptionId,
    token,
    appName,
  }: {
    subscriptionId: string
    token: string
    appName: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.DELETE,
      params: {
        token,
        app_name: appName,
      },
    }

    return this.api.makeRequest<never>(
      `v1/subscriptions/${subscriptionId}`,
      options,
    )
  }
}
