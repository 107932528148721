import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import { ContactForm } from 'modules/contactForm'
import { SubscriptionDetails } from 'modules/unsubscribe/pages/SubscriptionDetails'
import { UnsubscribeLogin } from 'modules/unsubscribeLogin'
import { UnsubscribeResetPassword } from 'modules/unsubscribeResetPassword'

export const RouteList: React.FC = () => {
  return useRoutes([
    { index: true, element: <Navigate to="login" /> },
    {
      path: 'login',
      element: <UnsubscribeLogin />,
    },
    {
      path: 'reset-password',
      element: <UnsubscribeResetPassword />,
    },
    {
      path: 'subscription-details',
      element: <SubscriptionDetails />,
    },
    { path: 'contact-form', element: <ContactForm /> },
    { path: '*', element: <Navigate to="login" /> },
  ])
}
