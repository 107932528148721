import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAppConfigAction } from 'root-redux/actions/common'
import { selectAppName } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

export const useInitAppConfig = (): void => {
  const dispatch: TAppDispatch = useDispatch()
  const appName = useSelector(selectAppName)

  useEffect(() => {
    if (!appName) return
    dispatch(getAppConfigAction(appName))
  }, [dispatch, appName])
}
