import styled from 'styled-components'

import { Color } from 'root-constants'

type TContainerProps = {
  color: string
}

export const StyledFooter = {
  LinksContainer: styled.div<TContainerProps>`
    margin-top: 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.BLACK};
    text-align: center;

    a {
      color: ${Color.BLACK};
      text-decoration: underline;
    }
  `,
}
