import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { FooterContainer } from 'components/FooterContainer'

import { Color } from 'root-constants'

type TFormProps = {
  backgroundColor: string
}

type TButtonProps = {
  color: string
}

export const StyledEmailLogin = {
  Title: styled.h1`
    margin-bottom: 24px;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    text-align: center;
  `,
  Subtitle: styled.p`
    color: ${Color.GRAY};
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
  `,
  Form: styled.form<TFormProps>`
    padding-bottom: 34px;
  `,
  Text: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
  `,
  BackToLogin: styled.div`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.BLACK};
    margin-top: 10px;
  `,
  BackToLoginBtn: styled.a`
    background-color: transparent;
    color: ${Color.LINK_COLOR};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
  `,
  ForgotPasswordButton: styled.button<TButtonProps>`
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-left: 16px;
    background-color: transparent;
    color: ${Color.GRAY};
  `,
  Image: styled.img`
    margin: 0 auto;
  `,
  CheckEmailTitle: styled.h1`
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    text-align: center;
    margin: 0 16px;
  `,
  FooterContainer: styled(FooterContainer)<{ $isStatic: boolean }>`
    ${({ $isStatic }) =>
      $isStatic &&
      css`
        position: static;
      `}
  `,
  Container: styled(Container)<{ $isStatic: boolean }>`
    padding-bottom: 140px;

    ${({ $isStatic }) =>
      $isStatic &&
      css`
        padding-bottom: 0;
      `}
  `,
}
