import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getAuth, onAuthStateChanged } from 'firebase/auth'

import { selectFirebaseConfig, selectSource } from 'root-redux/selects/common'
import { selectUserContactEmail } from 'root-redux/selects/user'

import { getLoginMethodFromLocalStorage } from 'helpers/getLoginMethodFromLocalStorage'

import { eventLogger } from 'services/eventLogger.service'

export const useAuthObserver = (callback: (token: string) => void): void => {
  const firebaseConfig = useSelector(selectFirebaseConfig)
  const source = useSelector(selectSource)
  const email = useSelector(selectUserContactEmail)

  useEffect(() => {
    if (!firebaseConfig) return

    const auth = getAuth()
    const unregisterAuthObserver = onAuthStateChanged(
      auth,
      async (user) => {
        if (!user) {
          eventLogger.logLoginPageShow(source)
          return
        }

        const token = await user.getIdToken()
        callback(token)
      },
      (error) => {
        eventLogger.logLoginFailed({
          error: error.message,
          source,
          email,
          method: getLoginMethodFromLocalStorage(),
        })
      },
    )

    // eslint-disable-next-line consistent-return
    return () => {
      unregisterAuthObserver()
    }
  }, [callback, email, firebaseConfig, source])
}
