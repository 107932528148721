import { captchaApi, zendeskApi } from 'api'
import i18n from 'i18next'
import { AnyAction } from 'redux'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { selectAppName } from 'root-redux/selects/common'

import { createZendeskRequest } from 'helpers/createZendeskRequest'

import { selectUploadedFiles } from 'modules/contactForm/redux/selects'

import { IAction, IAppState, TAppActionThunk } from 'models/store.model'
import { IContactForm, IUploadedFile } from 'models/zendesk.model'

import { NOVE8_APPS, ZENDESK_API_URLS } from 'root-constants'

const MODULE_NAME = 'CONTACT_US'

export const ADD_FILE = `${MODULE_NAME}/ADD_FILE`
export const REMOVE_FILE = `${MODULE_NAME}/REMOVE_FILE`
export const REMOVE_ALL_FILES = `${MODULE_NAME}/REMOVE_ALL_FILES`
export const SET_IS_MODAL_SHOWN = `${MODULE_NAME}/SET_IS_MODAL_SHOWN`
export const UPLOAD_FILES = `${MODULE_NAME}/UPLOAD_FILES`
export const SEND_FORM = `${MODULE_NAME}/SEND_FORM`
export const VERIFY_GOOGLE_CAPTCHA = `${MODULE_NAME}/VERIFY_GOOGLE_CAPTCHA`
export const SET_IS_CAPTCHA_VALID = `${MODULE_NAME}/SET_IS_CAPTCHA_VALID`

export function addFileAction({
  fileId,
  token,
  hasError,
}: IUploadedFile): AnyAction {
  return {
    type: ADD_FILE,
    payload: { fileId, token, hasError },
  }
}

export const removeFileAction = (fileId: string): IAction<string> => ({
  type: REMOVE_FILE,
  payload: fileId,
})

export const removeAllFilesAction = (): IAction<never> => ({
  type: REMOVE_ALL_FILES,
})

export const setIsModalShownAction = (value: boolean): IAction<boolean> => ({
  type: SET_IS_MODAL_SHOWN,
  payload: value,
})

export const setIsCaptchaValidAction = (value: boolean): IAction<boolean> => ({
  type: SET_IS_CAPTCHA_VALID,
  payload: value,
})

export function uploadFilesAction(files: File[]): TAppActionThunk<any> {
  return async (dispatch, getState: () => IAppState) => {
    const state = getState()
    const appName = selectAppName(state)
    const baseUrl = NOVE8_APPS.includes(appName)
      ? ZENDESK_API_URLS.nove8
      : ZENDESK_API_URLS.general

    dispatch(startFetching(UPLOAD_FILES))

    // eslint-disable-next-line no-restricted-syntax
    for await (const file of files) {
      try {
        const formData = new FormData()
        formData.append('uploaded_data', file, file.name)

        const response = await zendeskApi.uploadFile({
          fileName: file.name,
          data: formData,
          baseUrl,
        })

        if (response.success && response.data) {
          dispatch(
            addFileAction({
              fileId: `${file.name}_${file.lastModified}`,
              token: response.data.upload.token,
            }),
          )
        } else {
          dispatch(
            addFileAction({
              fileId: `${file.name}_${file.lastModified}`,
              hasError: true,
            }),
          )
        }

        dispatch(stopFetching(UPLOAD_FILES))
      } catch (error: any) {
        dispatch(stopFetching(UPLOAD_FILES))
      }
    }
  }
}

export function sendCustomFormAction(
  formData: IContactForm,
): TAppActionThunk<any> {
  return async (dispatch, getState: () => IAppState) => {
    const state = getState()
    const appName = selectAppName(state)
    const uploadedFiles = selectUploadedFiles(state)
    const baseUrl = NOVE8_APPS.includes(appName)
      ? ZENDESK_API_URLS.nove8
      : ZENDESK_API_URLS.general

    dispatch(startFetching(SEND_FORM))

    try {
      const response = await zendeskApi.sendForm(
        createZendeskRequest({ appName, formData, uploadedFiles }),
        baseUrl,
      )

      if (!response.success || !response.data) {
        dispatch(setErrorAction(i18n.t('login.error.common')))
      }

      dispatch(setIsModalShownAction(true))
      dispatch(stopFetching(SEND_FORM))
    } catch (error: any) {
      dispatch(setIsModalShownAction(true))
      dispatch(setErrorAction(i18n.t('login.error.common')))
      dispatch(stopFetching(SEND_FORM))
    }
  }
}

export function verifyGoogleCaptchaAction(
  captchaToken: string | null,
): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(VERIFY_GOOGLE_CAPTCHA))

    try {
      const response = await captchaApi.verifyCaptchaToken(captchaToken)

      if (!response.data.success) {
        dispatch(setIsCaptchaValidAction(false))
        dispatch(stopFetching(VERIFY_GOOGLE_CAPTCHA))
        return
      }

      dispatch(setIsCaptchaValidAction(true))
      dispatch(stopFetching(VERIFY_GOOGLE_CAPTCHA))
    } catch (error: any) {
      dispatch(setIsCaptchaValidAction(false))
      dispatch(stopFetching(VERIFY_GOOGLE_CAPTCHA))
    }
  }
}
