import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'storybook-ui'

import { Spinner } from 'components/Spinner'

import {
  CANCEL_EXTRA,
  UNSUBSCRIBE_SECONDARY,
  pauseSubscriptionAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectAppName,
  selectSource,
} from 'root-redux/selects/common'
import {
  selectCustomerAvailableFeatures,
  selectUUID,
  selectUserSecondariesSubscriptionInfo,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useCompatibleRetentionFlowPaymentMethod } from 'hooks/useCompatibleRetentionFlowPaymentMethod'

import { getSubscriptionUIStatus } from 'helpers/getSubscriptionUIStatus'

import { getBillingCycleKey } from 'modules/unsubscribe/helpers'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { SubscriptionContainer as Container } from 'common-styles'
import { PageId } from 'page-constants'
import {
  APP_BUTTON_THEMES,
  AppName,
  CURRENCY_SYMBOLS,
  EVENT_SOURCE,
  Language,
  MONTHS_IN_YEAR,
  SubscriptionInterval,
  SubscriptionType,
} from 'root-constants'

import { RetentionFlowContainer } from '../RetentionFlowContainer'
import { ExtraSubscription } from './ExtraSubscription'
import { InAppPurchases } from './InAppPurchases'
import { SecondarySubscription } from './SecondarySubscription'
import { StyledSubscriptions as S } from './Subscriptions.styles'
import {
  SMALLER_BUTTON_FONT_SIZE_LOCALES,
  STATUSES_TEXT_MAP,
  SUBSCRIPTION_DETAILS_MAP,
} from './constants'

export const DetailedSubscriptions: React.FC = () => {
  const { search } = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const appName = useSelector(selectAppName) as AppName
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const userSecondarySubscriptions = useSelector(
    selectUserSecondariesSubscriptionInfo,
  )
  const source = useSelector(selectSource)
  const { pauseAvailable } = useSelector(selectCustomerAvailableFeatures)
  const userId = useSelector(selectUUID)
  const fetchingActionsList = useSelector(selectActionList)
  const { isCanceledSubscription, isActiveSubscription, isPausedSubscription } =
    getSubscriptionUIStatus(userSubscriptionInfo?.uiStatus)

  const isCompatibleRetentionFlowPaymentMethod =
    useCompatibleRetentionFlowPaymentMethod()

  const userLocale = new Intl.Locale(i18n.language).language || Language.EN

  const isMonthlyOrYearlyBillingCycle =
    userSubscriptionInfo?.interval === SubscriptionInterval.MONTH ||
    userSubscriptionInfo?.interval === SubscriptionInterval.YEAR

  const monthsQty =
    userSubscriptionInfo?.interval === SubscriptionInterval.YEAR
      ? userSubscriptionInfo.intervalCount * MONTHS_IN_YEAR
      : userSubscriptionInfo?.intervalCount

  const isCancelSubscriptionInProgress =
    fetchingActionsList.includes(CANCEL_EXTRA) ||
    fetchingActionsList.includes(UNSUBSCRIBE_SECONDARY)

  const removeSubscription = () => {
    eventLogger.logTurnOffRenewalButtonTap({
      source,
      subscriptionType: SubscriptionType.SUBSCRIPTION,
      userId,
    })

    goTo({
      pathname: PageId.REACH_YOUR_GOAL,
      search,
    })
  }

  const pauseSubscription = async () => {
    eventLogger.logPauseSubscriptionButtonTap(source, userId)

    dispatch(pauseSubscriptionAction())

    goTo({
      pathname: PageId.CONTINUED_SUBSCRIPTION,
      search,
    })
  }

  const subscribeAgain = async () => {
    eventLogger.logContactSupportClicked(EVENT_SOURCE.ACCOUNT_PROFILE, userId)

    window.open('/contact-form', '_blank', 'noopener,noreferrer')
  }

  const isPauseSubscriptionAvailable =
    pauseAvailable &&
    isActiveSubscription &&
    isCompatibleRetentionFlowPaymentMethod

  const isTurnOffRenewalAvailable =
    isActiveSubscription ||
    (isPausedSubscription && isCompatibleRetentionFlowPaymentMethod)

  const isSmallerButtonFontSize =
    SMALLER_BUTTON_FONT_SIZE_LOCALES.includes(userLocale)

  if (!userSubscriptionInfo) {
    return null
  }

  const currency = CURRENCY_SYMBOLS[userSubscriptionInfo.currency.toLowerCase()]

  const SubscriptionDetails =
    SUBSCRIPTION_DETAILS_MAP[userSubscriptionInfo.uiStatus]

  return isCancelSubscriptionInProgress ? (
    <Spinner />
  ) : (
    <RetentionFlowContainer>
      <S.DetailedSubscriptionsWrapper>
        <Container.Root isBottomSeparatorHidden={isCanceledSubscription}>
          <Container.CardTitle>
            {t('subscription.yourPlan', { context: appName })}
          </Container.CardTitle>

          <S.SubscriptionRow>
            <span>
              <Trans i18nKey="subscription.status" components={[<br />]} />
            </span>
            <S.Status status={userSubscriptionInfo.uiStatus}>
              <Trans
                i18nKey={STATUSES_TEXT_MAP[userSubscriptionInfo.uiStatus]}
                components={[<br />]}
              />
            </S.Status>
          </S.SubscriptionRow>

          {SubscriptionDetails && <SubscriptionDetails />}

          {!isCanceledSubscription && (
            <>
              <S.Separator />
              <S.SubscriptionRow>
                <span>{t('subscription.billingPeriod')}</span>
                <Container.InfoValue>
                  {isMonthlyOrYearlyBillingCycle
                    ? t('subscription.billingPeriods.month', {
                        count: monthsQty,
                      })
                    : t(
                        `subscription.trialBillingPeriods.${getBillingCycleKey(
                          userSubscriptionInfo.intervalCount,
                        )}`,
                        {
                          count: userSubscriptionInfo.intervalCount,
                        },
                      )}
                </Container.InfoValue>
              </S.SubscriptionRow>
              <S.SubscriptionRow>
                <span>{t('subscription.nextChargeDate')}</span>
                <Container.InfoValue>
                  {userSubscriptionInfo.nextPayment}
                </Container.InfoValue>
              </S.SubscriptionRow>
              <S.SubscriptionRow>
                <span>{t('subscription.subscriptionPrice')}</span>
                <Container.InfoValue>
                  {t('subscription.priceAmount', {
                    currency,
                    amount:
                      userSubscriptionInfo.subAmount ||
                      userSubscriptionInfo.price,
                  })}
                </Container.InfoValue>
              </S.SubscriptionRow>
            </>
          )}

          {isPauseSubscriptionAvailable && (
            <Button
              theme={APP_BUTTON_THEMES[appName]}
              onClick={pauseSubscription}
              style={{
                margin: '16px auto',
                ...(isSmallerButtonFontSize && { fontSize: '14px' }),
              }}
            >
              {t('subscription.pauseSubscription')}
            </Button>
          )}
          {isPausedSubscription && (
            <Button
              theme={APP_BUTTON_THEMES[appName]}
              onClick={subscribeAgain}
              style={{
                margin: '16px auto',
              }}
            >
              {t('subscription.subscribeAgain')}
            </Button>
          )}

          {isTurnOffRenewalAvailable && (
            <Container.ResetPassword onClick={removeSubscription}>
              {t('subscription.turnOffRenewal')}
            </Container.ResetPassword>
          )}
        </Container.Root>

        {userSecondarySubscriptions.map((subscription) => (
          <SecondarySubscription
            key={subscription.subscriptionId}
            secondarySubscription={subscription}
          />
        ))}

        {userSubscriptionInfo.extras?.map((extra) => (
          <ExtraSubscription key={extra.id} extra={extra} />
        ))}
        <InAppPurchases />
      </S.DetailedSubscriptionsWrapper>
    </RetentionFlowContainer>
  )
}
