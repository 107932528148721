export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export const ALPHABETIC_SYMBOLS_REGEXP = /[^\p{L}\p{Z}]+/gu
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const MIN_PASSWORD_LENGTH = 6
export const NO_DATA_ERROR = 'No data'

export const enum AppName {
  LUVLY = 'facetory',
  DANCEBIT = 'dancebit',
  NUTRIMATE = 'myfast',
  FAMIO = 'famio',
  UPLUV = 'upluv',
  WOOFZ = 'woofz',
  CARDIMATE = 'cardimate',
  FITME = 'fitme',
  MEOWZ = 'meowz',
}

export const APP_NAMES = {
  luvly: AppName.LUVLY,
  dancebit: AppName.DANCEBIT,
  'dance-bit': AppName.DANCEBIT,
  fasting: AppName.NUTRIMATE,
  nutrimate: AppName.NUTRIMATE,
  famio: AppName.FAMIO,
  myfamio: AppName.FAMIO,
  upluv: AppName.UPLUV,
  woofz: AppName.WOOFZ,
  meowz: AppName.MEOWZ,
  cardimate: AppName.CARDIMATE,
  fitme: AppName.FITME,
  localhost: AppName.FAMIO,
}

export const NOVE8_APPS: string[] = [AppName.MEOWZ, AppName.WOOFZ]

export const FIREBASE_ERRORS = {
  WRONG_PASSWORD: 'auth/wrong-password',
  USER_NOT_FOUND: 'auth/user-not-found',
  USER_DISABLED: 'auth/user-disabled',
  INVALID_EMAIL: 'auth/invalid-email',
}

export const enum Color {
  LUVLY = '#628f7b',
  DANCEBIT = '#03aaf5',
  NUTRIMATE = '#a9cd50',
  FAMIO = '#6336ff',
  UPLUV = '#4946d2',
  WOOFZ = '#35b43f',
  CARDIMATE = '#2573ff',
  FITME = '#8378ef',
  SUCCESS = '#93c451',
  ERROR = '#f83b00',
  WHITE = '#fff',
  DARK = '#262634',
  GRAY = '#626262',
  GRAY_20 = '#e9e9e9',
  LINK_COLOR = '#0091ff',
  BLACK = '#000',
}

export const APP_COLORS = {
  [AppName.LUVLY]: Color.LUVLY,
  [AppName.DANCEBIT]: Color.DANCEBIT,
  [AppName.NUTRIMATE]: Color.NUTRIMATE,
  [AppName.FAMIO]: Color.FAMIO,
  [AppName.UPLUV]: Color.UPLUV,
  [AppName.WOOFZ]: Color.WOOFZ,
  [AppName.MEOWZ]: Color.WOOFZ,
  [AppName.CARDIMATE]: Color.CARDIMATE,
  [AppName.FITME]: Color.FITME,
}

export const APP_BG_COLORS = {
  [AppName.LUVLY]: '#f6f4ef',
  [AppName.DANCEBIT]: '#e6f7fe',
  [AppName.NUTRIMATE]: '#f6faee',
  [AppName.FAMIO]: '#efebff',
  [AppName.UPLUV]: '#ededfb',
  [AppName.WOOFZ]: '#fffaf7',
  [AppName.MEOWZ]: '#fffaf7',
  [AppName.CARDIMATE]: '#f0f2fa',
  [AppName.FITME]: '#f5f4ff',
}

export const ZENDESK_APP_NAMES = {
  [AppName.LUVLY]: 'Luvly',
  [AppName.DANCEBIT]: 'Dancebit',
  [AppName.NUTRIMATE]: 'Nutrimate',
  [AppName.FAMIO]: 'Famio',
  [AppName.UPLUV]: 'UpLuv',
  [AppName.WOOFZ]: 'Woofz',
  [AppName.MEOWZ]: 'Nove8',
  [AppName.CARDIMATE]: 'Cardimate',
  [AppName.FITME]: 'Fitme',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const CENTS_IN_DOLLAR = 100
export const DAYS_IN_WEEK = 7
export const DAYS_IN_MONTH = 30
export const MONTHS_IN_YEAR = 12

export const enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  CANCELLED = 'cancelled',
  NOT_ACTIVE = 'not_active',
  DEACTIVATED = 'deactivated',
  PAST_DUE = 'past_due',
}

export const SUPPORT_EMAILS = {
  [AppName.LUVLY]: 'support@luvly.care',
  [AppName.DANCEBIT]: 'support@dance-bit.com',
  [AppName.NUTRIMATE]: 'support@nutrimate.fit',
  [AppName.FAMIO]: 'support@myfamio.com',
  [AppName.UPLUV]: 'upluv-support@gismart.com',
  [AppName.WOOFZ]: 'support@woofz.com',
  [AppName.MEOWZ]: 'support@meowz.app',
  [AppName.CARDIMATE]: 'support@cardimate.com',
  [AppName.FITME]: 'support@fitme.health',
}

export const APP_TERMS_OF_USE_LINKS = {
  [AppName.LUVLY]: 'https://luvly.care/terms-of-use',
  [AppName.DANCEBIT]: 'https://www.dancebit.dance/terms-of-use',
  [AppName.FAMIO]: 'https://myfamio.com/terms-of-use',
  [AppName.UPLUV]: 'https://harmonybit.com/terms-of-use',
  [AppName.NUTRIMATE]: 'https://nutrimate.fit/terms-of-use',
  [AppName.WOOFZ]: 'https://www.woofz.com/terms-of-use/',
  [AppName.MEOWZ]: 'https://meowz.app/terms_of_use',
  [AppName.CARDIMATE]: 'https://cardimate.com/terms-of-use',
  [AppName.FITME]: 'https://quiz.fitme.health/terms-of-use',
}

export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'custom_token'

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const OPEN_APP_LINKS = {
  [AppName.LUVLY]: {
    [LoginMethod.EMAIL]:
      'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Password&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dpassword',
    [LoginMethod.GOOGLE]:
      'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Google&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dgoogle.com',
    [LoginMethod.FACEBOOK]:
      'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Facebook&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dfacebook.com',
    [LoginMethod.APPLE]:
      'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Apple&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dapple.com',
  },
  [AppName.FAMIO]: {
    [LoginMethod.EMAIL]:
      'https://famio.onelink.me/b9Ag?af_xp=custom&pid=web2web_payment_success&deep_link_value=custom_token&deep_link_sub1=password',
    [LoginMethod.APPLE]:
      'https://famio.onelink.me/b9Ag?af_xp=custom&pid=web2web_payment_success&deep_link_value=custom_token&deep_link_sub1=apple.com',
  },
  [AppName.UPLUV]: {
    [LoginMethod.EMAIL]:
      'https://upluv.onelink.me/5Qwq/mv3abvne?deep_link_value=custom_token',
  },
  [AppName.WOOFZ]: {
    [LoginMethod.EMAIL]:
      'https://qa-test.onelink.me/8Y0L/1b9c1ed6?deep_link_value=custom_token',
  },
  [AppName.MEOWZ]: {
    [LoginMethod.EMAIL]:
      'https://qa-test.onelink.me/8Y0L/1b9c1ed6?deep_link_value=custom_token',
  },
  [AppName.CARDIMATE]: {
    [LoginMethod.EMAIL]:
      'https://cardimate.onelink.me/AxaV/dzf0oh2m?deep_link_value=custom_token&deep_link_sub1=password',
    [LoginMethod.APPLE]:
      'https://cardimate.onelink.me/AxaV/dzf0oh2m?deep_link_value=custom_token&deep_link_sub1=apple.com',
  },
  [AppName.FITME]: {
    [LoginMethod.EMAIL]:
      'https://fitmeworkouts.onelink.me/69GB?af_xp=custom&deep_link_value=custom_token&pid=web2web_payment_success&c=FitMe_DL_Login&deep_link_sub1=password',
    [LoginMethod.APPLE]:
      'https://fitmeworkouts.onelink.me/69GB?af_xp=custom&deep_link_value=custom_token&pid=web2web_payment_success&c=FitMe_DL_Login&deep_link_sub1=apple.com',
  },
  [AppName.DANCEBIT]: {
    [LoginMethod.EMAIL]:
      'https://dancebit.onelink.me/cJoT?pid=web2web_payment_success&c=Dancebit_DL_Password&deep_link_value=custom_token&af_dp=harmonybitdancebit%3A%2F%2F%3Flogin%3D&deep_link_sub1=password',
  },
  [AppName.NUTRIMATE]: {
    [LoginMethod.EMAIL]:
      'https://myfast.onelink.me/i1q0?pid=web2web_payment_success&c=myfast_DL_Email&deep_link_value=custom_token&af_dp=myfast%3A%2F%2F%3Flogin%3D&deep_link_sub1=password',
    [LoginMethod.APPLE]:
      'https://myfast.onelink.me/i1q0?pid=web2web_payment_success&c=myfast_DL_Email&deep_link_value=custom_token&af_dp=myfast%3A%2F%2F%3Flogin%3D&deep_link_sub1=apple.com',
  },
}

export const APP_PRIVACY_POLICY_LINKS = {
  [AppName.LUVLY]: 'https://luvly.care/privacy-policy',
  [AppName.DANCEBIT]: 'https://www.dancebit.dance/privacy-policy',
  [AppName.FAMIO]: 'https://myfamio.com/privacy-policy',
  [AppName.UPLUV]: 'https://upluv.one/privacy-policy',
  [AppName.NUTRIMATE]: 'https://nutrimate.fit/privacy-policy',
  [AppName.WOOFZ]: 'https://www.woofz.com/privacy-policy/',
  [AppName.MEOWZ]: 'https://meowz.app/privacy_policy',
  [AppName.CARDIMATE]: 'https://cardimate.com/privacy-policy',
  [AppName.FITME]: 'https://quiz.fitme.health/privacy-policy',
}

export const CONTACT_FORM_LINK = '/contact-form'

export const SUPPORT_FORM_LINKS = {
  [AppName.DANCEBIT]: 'https://support.dance-bit.com/hc/en-us',
}

export const ZENDESK_API_URLS = {
  general: 'https://gismart.zendesk.com/api',
  nove8: 'https://nove8help.zendesk.com/api',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum CurrentEnvironment {
  DEV = 'dev',
  PROD = 'prod',
}

export const enum Source {
  APP = 'mobile_app',
  SUPPORT = 'support',
}

export const enum SubscriptionInterval {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const enum TrialBillingCycle {
  ONE_DAY = 1,
  TWO_DAYS = 2,
  THREE_DAYS = 3,
  FOUR_DAYS = 4,
  SIX_DAYS = 6,
  SEVEN_DAYS = 7,
  FOUR_WEEKS = 28,
  ONE_MONTH = 30,
  TWELVE_WEEKS = 84,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export const FIXED_TERM_TRIAL_CYCLES = [
  TrialBillingCycle.ONE_DAY,
  TrialBillingCycle.TWO_DAYS,
  TrialBillingCycle.THREE_DAYS,
  TrialBillingCycle.FOUR_DAYS,
  TrialBillingCycle.SIX_DAYS,
  TrialBillingCycle.SEVEN_DAYS,
  TrialBillingCycle.FOUR_WEEKS,
  TrialBillingCycle.TWELVE_WEEKS,
]
export const TRIAL_DAILY_PERIOD = 'day'
export const TRIAL_MONTHLY_PERIOD = 'months'
export const TRIAL_BILLING_CYCLE_KEYS = {
  [TrialBillingCycle.ONE_MONTH]: 'month',
  [TrialBillingCycle.SEVEN_DAYS]: 'week',
  [TrialBillingCycle.FOUR_WEEKS]: 'weeks',
  [TrialBillingCycle.TWELVE_WEEKS]: 'weeks',
}

export const enum EVENT_SOURCE {
  ACCOUNT_PROFILE = 'account_details_page',
  PAYWALL = 'paywall',
  RESET_PASSWORD = 'reset_password_screen',
  UNSUBSCRIBE_LOGIN = 'unsub_login_page',
}

export const LOGIN_METHOD_KEY = 'method'
