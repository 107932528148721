import { IRequestOptions, IResponseResult } from 'models/api.model'
import { IGetUserStatusResponseRaw } from 'models/commonApi.model'

import { ApiService } from 'services/api.service'

import { RequestMethod } from 'root-constants'

export class UserApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getUserStatus(
    uuid: string,
    appName: string,
  ): Promise<IResponseResult<IGetUserStatusResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<string>(`v2/leads/${uuid}/${appName}`, options)
  }

  getAutologinToken(
    uuid: string,
    appName: string,
  ): Promise<IResponseResult<{ status: boolean; custom_token: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid,
        app_name: appName,
      },
    }

    return this.api.makeRequest<never>(`v1/flow/login`, options)
  }
}
