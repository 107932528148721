import React, { ButtonHTMLAttributes } from 'react'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'

import { APP_COLORS } from 'root-constants'

import { StyledButton } from './Button.styles'

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  buttonRef?: React.Ref<HTMLButtonElement>
}

export const Button: React.FC<IProps> = ({
  children,
  buttonRef = null,
  ...props
}) => {
  const appName = useSelector(selectAppName)

  return (
    <StyledButton
      ref={buttonRef}
      {...props}
      backgroundColor={APP_COLORS[appName]}
    >
      {children}
    </StyledButton>
  )
}
