import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import de from 'assets/i18n/de.json'
import en from 'assets/i18n/en.json'
import es from 'assets/i18n/es.json'
import fr from 'assets/i18n/fr.json'
import it from 'assets/i18n/it.json'
import pt from 'assets/i18n/pt.json'

import { DAYS_IN_MONTH, DAYS_IN_WEEK } from 'root-constants'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator'],
    },
    load: 'languageOnly',
    resources: {
      en,
      fr,
      es,
      de,
      it,
      pt,
    },
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

i18n.services.formatter?.addCached(
  'convertDaysToWeeks',
  () => (value) => `${Math.round(value / DAYS_IN_WEEK)}`,
)

i18n.services.formatter?.addCached(
  'convertDaysToMonths',
  () => (value) => `${Math.round(value / DAYS_IN_MONTH)}`,
)
