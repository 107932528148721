import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { selectAppName } from 'root-redux/selects/common'

import { APP_COLORS, EVENT_SOURCE } from 'root-constants'

import { StyledFooter as S } from './Footer.styles'

export const Footer: React.FC<{ source: EVENT_SOURCE }> = ({ source }) => {
  const appName = useSelector(selectAppName)
  const { t } = useTranslation()

  return (
    <S.LinksContainer color={APP_COLORS[appName]}>
      <TermsOfUseLink source={source} />
      {t('login.and')}
      <PrivacyPolicyLink source={source} />
    </S.LinksContainer>
  )
}
