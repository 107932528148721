import { IAppState } from 'models/store.model'
import { IUploadedFile } from 'models/zendesk.model'

export const selectUploadedFiles = (state: IAppState): IUploadedFile[] =>
  state.contactForm.uploadedFiles || []

export const selectIsModalShown = (state: IAppState): boolean =>
  state.contactForm.isModalShown

export const selectIsCaptchaValid = (state: IAppState): boolean =>
  state.contactForm.isCaptchaValid
