import { IAppConfig, IAppConfigRaw } from 'models/config.model'

export const getAppConfigFromConfigRaw = (
  config: IAppConfigRaw,
): IAppConfig => ({
  applicationName: config.application_name,
  amplitudeApiKey: config.amplitude_api_key,
  firebaseConfig: config.firebase_config,
  giaApiKeys: config.gia_api_keys,
  googleAnalyticsId: config.google_analytics_id,
  googleOptimizeId: config.google_optimize_id,
})
