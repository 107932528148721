import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'

import { META_INFO } from './constants'

export const MetaInfo: React.FC = () => {
  const appName = useSelector(selectAppName)

  return !appName ? null : (
    <Helmet>
      <title>{META_INFO[appName].title}</title>
      <link rel="icon" href={META_INFO[appName].favicon} />
    </Helmet>
  )
}
