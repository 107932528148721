import { ALPHABETIC_SYMBOLS_REGEXP, EMAIL_REGEXP } from 'root-constants'

export const EMAIL_DOMAINS = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'live.com',
  'msn.com',
  'me.com',
  'mac.com',
  'googlemail.com',
  'facebook.com',
  'verizon.net',
  'rediffmail.com',
  'zohomail.com',
  'zoho.com',
  'mail.com',
  'google.com',
  'comcast.com',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'att.net',
  'gmz.com',
]

export const REQUEST_TOPICS = [
  { value: `Didn't receive the product`, name: `Didn't receive the product` },
  { value: 'Login issues', name: 'Login issues' },
  { value: 'Billing issues', name: 'Billing issues' },
  { value: 'Cancel subscription', name: 'Cancel subscription' },
  { value: 'How to use', name: 'How to use' },
  { value: 'Other questions', name: 'Other questions' },
]

export const NOVE8_REQUEST_TOPICS = [
  { value: 'My subscription', name: 'My subscription' },
  { value: 'Login issue', name: 'Login issue' },
  { value: 'Product features', name: 'Product features' },
  { value: 'Report a bug', name: 'Report a bug' },
  { value: 'Need advice with training', name: 'Need advice with training' },
  { value: 'Other', name: 'Other' },
]

export const SUPPORTED_FILE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'webp',
  'bmp',
  'mp4',
  'avi',
  'mov',
  'wmv',
  'mkv',
  'webm',
  'flv',
  'ogg',
  '3gp',
  'm4v',
]

export const MAX_FILE_NUMBER = 3
export const MAX_FILE_SIZE = 50 * 1024 * 1024
export const EMAIL_DOMAIN_REGEXP = /@(.*)/
export const EMAIL_USERNAME_REGEXP = /(.*)@/
export const ATTACHMENTS_NUMBER_ERROR = 'You can upload a maximum of 3 files.'
export const ATTACHMENTS_SIZE_ERROR = 'File must be less than 50 MB'

export const NAME_VALIDATION = {
  minLength: (v) => v.length >= 1 || 'Name should have at least one character',
  maxLength: (v) => v.length <= 31 || 'Name should have up to 31 characters',
  matchPattern: (v) =>
    !ALPHABETIC_SYMBOLS_REGEXP.test(v) || 'Name should be valid',
}
export const EMAIL_VALIDATION = {
  maxLength: (v) =>
    v.length <= 50 || 'The email should have up to 50 characters',
  matchPattern: (v) =>
    EMAIL_REGEXP.test(v) || 'Please enter a valid email address',
}
