import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'
import {
  selectUserSecondariesSubscriptionInfo,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'

import { getBillingCycleKey } from 'modules/unsubscribe/helpers'

import { SubscriptionContainer as Container } from 'common-styles'
import { AppName, CURRENCY_SYMBOLS } from 'root-constants'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import { FREE_EXTRA_PROGRAMS, TSubscriptionDetailsProps } from './constants'

export const TrialSubscriptionDetails: React.FC<TSubscriptionDetailsProps> = ({
  extra,
  secondary,
}: TSubscriptionDetailsProps) => {
  const { t } = useTranslation()
  const subscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const secondarySubscriptionInfo = useSelector(
    selectUserSecondariesSubscriptionInfo,
  )
  const appName = useSelector(selectAppName)
  const userSubscriptionInfo =
    appName === AppName.WOOFZ && secondary
      ? secondarySubscriptionInfo[0]
      : subscriptionInfo

  if (!userSubscriptionInfo) {
    return null
  }

  const trialPrice = extra
    ? extra.trialPrice
    : userSubscriptionInfo.trialSubAmount || userSubscriptionInfo.trialPrice

  const trialDays = extra?.trialDaysCount || userSubscriptionInfo.trialDaysCount

  const isFreeExtraProgram =
    extra &&
    (FREE_EXTRA_PROGRAMS.includes(extra.productId) ||
      (appName === AppName.FITME && !trialPrice && !!trialDays))

  const currency = CURRENCY_SYMBOLS[userSubscriptionInfo.currency.toLowerCase()]

  return (
    <>
      <S.SubscriptionRow>
        <span>
          {t(
            extra
              ? 'subscription.extraProgram.startDate'
              : 'subscription.startDate',
          )}
        </span>
        <Container.InfoValue>
          {userSubscriptionInfo.trialStartDate ||
            userSubscriptionInfo.startDate}
        </Container.InfoValue>
      </S.SubscriptionRow>
      {!!trialDays && (
        <S.SubscriptionRow>
          <span>{t('subscription.trialPeriod')}</span>
          <Container.InfoValue>
            {t(
              `subscription.trialBillingPeriods.${getBillingCycleKey(
                userSubscriptionInfo.trialDaysCount,
              )}`,
              {
                count: userSubscriptionInfo.trialDaysCount,
              },
            )}
          </Container.InfoValue>
        </S.SubscriptionRow>
      )}
      {(!!trialPrice || isFreeExtraProgram) && (
        <S.SubscriptionRow>
          <span>{t('subscription.trialPrice')}</span>
          <Container.InfoValue>
            {t('subscription.priceAmount', {
              currency,
              amount: trialPrice,
            })}
          </Container.InfoValue>
        </S.SubscriptionRow>
      )}
    </>
  )
}
