import { combineReducers } from 'redux'

import { commonReducer } from 'root-redux/reducers/common'
import { userReducer } from 'root-redux/reducers/user'

import { contactFormReducer } from 'modules/contactForm/redux'
import { unsubscribeLoginReducer } from 'modules/unsubscribeLogin/redux'

import { IAppState } from 'models/store.model'

export const rootReducer = combineReducers<IAppState>({
  common: commonReducer,
  user: userReducer,
  unsubscribeLogin: unsubscribeLoginReducer,
  contactForm: contactFormReducer,
})
