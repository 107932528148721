import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Footer } from 'components/Footer'
import { FooterContainer } from 'components/FooterContainer'
import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'

import { setUserContactEmailAction } from 'root-redux/actions/user'
import {
  selectActionList,
  selectAppName,
  selectError,
  selectSource,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useEmailInputField } from 'hooks/useEmailInputField'

import {
  LOGIN_WITH_EMAIL_FIREBASE,
  RESET_PASSWORD,
  resetFirebaseErrorMessageAction,
  resetFirebaseInfoMessageAction,
  resetUserPassword,
  selectFirebaseErrorMessage,
  selectIsPasswordReset,
  setIsPasswordResetAction,
} from 'modules/unsubscribeLogin/redux'

import { eventLogger } from 'services/eventLogger.service'

import envelopeImg from 'assets/images/envelope.png'

import { goTo } from 'browser-history'
import { APP_BG_COLORS, EVENT_SOURCE } from 'root-constants'

import { StyledResetPassword as S } from './ResetPassword.styles'

export const ResetPassword: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const appName = useSelector(selectAppName)
  const source = useSelector(selectSource)
  const fetchingActionsList = useSelector(selectActionList)
  const isPasswordReset = useSelector(selectIsPasswordReset)
  const errorMessage = useSelector(selectError)
  const firebaseErrorMessage = useSelector(selectFirebaseErrorMessage)
  const { t } = useTranslation()

  const [email, setEmail] = useEmailInputField()

  const isFetching = useMemo(
    () =>
      fetchingActionsList.includes(RESET_PASSWORD) ||
      fetchingActionsList.includes(LOGIN_WITH_EMAIL_FIREBASE),
    [fetchingActionsList],
  )

  const errorNotificationMessage = useMemo(
    () => errorMessage || firebaseErrorMessage,
    [errorMessage, firebaseErrorMessage],
  )

  const resetPassword = useCallback(
    (event) => {
      event.preventDefault()
      eventLogger.logResetLinkClicked(source)

      if (email.value && email.isValid) {
        dispatch(resetUserPassword(email.value))
      }
    },
    [source, email.value, email.isValid, dispatch],
  )

  const resetError = useCallback(() => {
    dispatch(resetFirebaseErrorMessageAction())
  }, [dispatch])

  const backToLogin = useCallback(() => {
    dispatch(resetFirebaseErrorMessageAction())
    dispatch(resetFirebaseInfoMessageAction())
    dispatch(setIsPasswordResetAction(false))

    dispatch(setUserContactEmailAction(email.value))

    eventLogger.logResetPageClosed(source)

    goTo('login')
  }, [dispatch, email.value, source])

  if (isPasswordReset) {
    return (
      <>
        <Container>
          <S.Image src={envelopeImg} width={149} height={149} alt="envelope" />
          <S.CheckEmailTitle>{t('login.checkEmail')}</S.CheckEmailTitle>
          <S.Subtitle>{t('login.checkEmailSubtitle')}</S.Subtitle>
        </Container>

        <FooterContainer>
          <Button onClick={backToLogin}>{t('login.gotIt')}</Button>
          <Footer source={EVENT_SOURCE.RESET_PASSWORD} />
        </FooterContainer>
      </>
    )
  }

  return (
    <>
      <Container>
        <S.Title>{t('login.reset')}</S.Title>
        <S.Subtitle>{t('login.resetSubtitle')}</S.Subtitle>
        <S.Form
          backgroundColor={APP_BG_COLORS[appName]}
          onSubmit={resetPassword}
        >
          <InputWithFloatPlaceholder
            value={email.value}
            isValid={email.isValid && !errorNotificationMessage}
            validationText={email.validationText || errorNotificationMessage}
            labelName={t('login.email')}
            marginBottom={12}
            onChange={(e) => {
              setEmail((prevState) => ({ ...prevState, value: e.target.value }))
              resetError()
            }}
          />
        </S.Form>
      </Container>

      <FooterContainer>
        <Button
          type="submit"
          disabled={!email.isValid || !email.value || isFetching}
          onClick={resetPassword}
        >
          {t('login.sendResetLink')}
        </Button>
        <S.BackToLogin>
          {t('login.returnToLogin')}
          <S.BackToLoginBtn onClick={backToLogin}>
            {t('login.returnToLoginBtn')}
          </S.BackToLoginBtn>
        </S.BackToLogin>
        <Footer source={EVENT_SOURCE.RESET_PASSWORD} />
      </FooterContainer>
    </>
  )
}
