import styled from 'styled-components'

export const StyledUnsubscribeLogin = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(var(--full-height, 100vh) - 61px);
  `,
}
