import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDomainsContainer = {
  DomainsContainer: styled.div`
    position: absolute;
    left: 0;
    top: 70px;
    width: 100%;
    max-height: 160px;
    padding: 0 16px;
    border-radius: 10px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px #e8e8e8;
    overflow-y: auto;
    z-index: 1;

    button {
      display: block;
      width: 100%;
      padding: 8px 0;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #e9e9e9;
      text-align: left;
      cursor: pointer;
      outline: none;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

      &:hover {
        font-weight: 600;
      }
    }

    button:last-child {
      border-bottom: 1px solid transparent;
    }
  `,
}
