import React, { FC, ReactNode } from 'react'

import { FooterContainerStyles as S } from './FooterContainer.styles'

type TProps = {
  children?: ReactNode
  className?: string
}

export const FooterContainer: FC<TProps> = ({ children, className = '' }) => {
  return <S.Container className={className}>{children}</S.Container>
}
