import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import { APP_PRIVACY_POLICY_LINKS, EVENT_SOURCE } from 'root-constants'

import { OuterLink } from '../OuterLink'

export const PrivacyPolicyLink: React.FC<{ source: EVENT_SOURCE }> = ({
  source,
}) => {
  const userId = useSelector(selectUUID)
  const appName = useSelector(selectAppName)
  const { t } = useTranslation()

  return (
    <OuterLink
      href={APP_PRIVACY_POLICY_LINKS[appName]}
      onClick={() => eventLogger.logPrivacyPolicyClicked(source, userId)}
    >
      {t('login.privacyPolicy')}
    </OuterLink>
  )
}
