import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { unsubscribeUserByTokenAction } from 'root-redux/actions/user'
import { selectAppName } from 'root-redux/selects/common'
import { selectUserSubscriptionInfo } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { SubscriptionContainer as Container } from 'common-styles'
import {
  AppName,
  CURRENCY_SYMBOLS,
  Color,
  MONTHS_IN_YEAR,
  SubscriptionInterval,
  SubscriptionStatus,
  TRIAL_BILLING_CYCLE_KEYS,
  TRIAL_DAILY_PERIOD,
  TRIAL_MONTHLY_PERIOD,
  TrialBillingCycle,
} from 'root-constants'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import { CANCELLED_STATUSES } from './constants'

export const Subscriptions: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const appName = useSelector(selectAppName) as AppName
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)

  const isCancelled =
    userSubscriptionInfo &&
    CANCELLED_STATUSES.includes(userSubscriptionInfo.status)

  const isMonthlyOrYearlyBillingCycle =
    userSubscriptionInfo?.interval === SubscriptionInterval.MONTH ||
    userSubscriptionInfo?.interval === SubscriptionInterval.YEAR
  const monthsQty =
    userSubscriptionInfo?.interval === SubscriptionInterval.YEAR
      ? userSubscriptionInfo.intervalCount * MONTHS_IN_YEAR
      : userSubscriptionInfo?.intervalCount
  const getBillingCycleKey = (daysCount: number) => {
    if (daysCount < TrialBillingCycle.SEVEN_DAYS) return TRIAL_DAILY_PERIOD
    return TRIAL_BILLING_CYCLE_KEYS[daysCount] || TRIAL_MONTHLY_PERIOD
  }

  const removeSubscription = () => {
    dispatch(unsubscribeUserByTokenAction())
  }

  if (!userSubscriptionInfo) {
    return null
  }

  const currency = CURRENCY_SYMBOLS[userSubscriptionInfo.currency.toLowerCase()]

  return isCancelled ? (
    <Container.Root backgroundColor={Color.GRAY_20}>
      <Container.CardTitle>
        {t('subscription.yourPlan', { context: appName })}
      </Container.CardTitle>
      <S.UnsubscribeInfo>
        <Trans
          i18nKey="subscription.cancel.text"
          values={{ context: appName }}
        />
      </S.UnsubscribeInfo>
    </Container.Root>
  ) : (
    <Container.Root>
      <Container.CardTitle>
        {t('subscription.yourPlan', { context: appName })}
      </Container.CardTitle>

      {userSubscriptionInfo.status === SubscriptionStatus.TRIAL && (
        <>
          <S.SubscriptionRow>
            <span>{t('subscription.trialPeriod')}</span>
            <Container.InfoValue>
              {t(
                `subscription.trialBillingPeriods.${getBillingCycleKey(
                  userSubscriptionInfo.trialDaysCount,
                )}`,
                {
                  count: userSubscriptionInfo.trialDaysCount,
                },
              )}
            </Container.InfoValue>
          </S.SubscriptionRow>
          <S.SubscriptionRow>
            <span>{t('subscription.trialPrice')}</span>
            <Container.InfoValue>
              {t('subscription.priceAmount', {
                currency,
                amount: userSubscriptionInfo.trialPrice,
              })}
            </Container.InfoValue>
          </S.SubscriptionRow>
          <S.Separator />
        </>
      )}
      <S.SubscriptionRow>
        <span>{t('subscription.billingPeriod')}</span>
        <Container.InfoValue>
          {isMonthlyOrYearlyBillingCycle
            ? t('subscription.billingPeriods.month', {
                count: monthsQty,
              })
            : t(
                `subscription.trialBillingPeriods.${getBillingCycleKey(
                  userSubscriptionInfo.intervalCount,
                )}`,
                {
                  count: userSubscriptionInfo.intervalCount,
                },
              )}
        </Container.InfoValue>
      </S.SubscriptionRow>
      <S.SubscriptionRow>
        <span>{t('subscription.nextChargeDate')}</span>
        <Container.InfoValue>
          {userSubscriptionInfo.endDate}
        </Container.InfoValue>
      </S.SubscriptionRow>
      <S.SubscriptionRow>
        <span>{t('subscription.subscriptionPrice')}</span>
        <Container.InfoValue>
          {t('subscription.priceAmount', {
            currency,
            amount: userSubscriptionInfo.price,
          })}
        </Container.InfoValue>
      </S.SubscriptionRow>

      <Container.ResetPassword onClick={removeSubscription}>
        {t('subscription.turnOffRenewal')}
      </Container.ResetPassword>
    </Container.Root>
  )
}
