import { IEvent, IEventLogger } from 'models/events.model'

import { EVENT_SOURCE, EventLoggerInstanceName } from 'root-constants'

export const enum Events {
  LOGIN_PAGE_SHOW = 'account_login_screen_show',
  LOGIN_COMPLETED = 'account_login_completed',
  LOGIN_FAILED = 'account_login_failed',
  OPEN_APP = 'account_page_app_open_tap',
  SUBSCRIPTION_DETAILS_SHOW = 'subscription_details_screen_show',
  SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
  CANCEL_SUBSCRIPTION_FAILED = 'cancel_subscription_failed',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  CONTACT_SUPPORT = 'contact_support_tap',
  HELP_CENTER = 'help_centre_tap',
  SUPPORT_VIDGET_TAP = 'support_vidget_tap',
  RESET_LINK = 'account_login_reset_link_send_tap',
  RESET_PAGE_CLOSED = 'reset_link_complete_screen_close',
  FORGOT_PASSWORD = 'account_login_forgot_password_tap',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []
  private eventsCache: Events[] = []

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )

    this.loggers = new Map(entriesArr)

    this.notifyInitFinished()
  }

  logLoginPageShow(source: string): void {
    if (this.eventsCache.includes(Events.LOGIN_PAGE_SHOW)) {
      return
    }

    this.eventsCache.push(Events.LOGIN_PAGE_SHOW)
    const eventProperty = { source }
    this.logEventOrPushToQueue(Events.LOGIN_PAGE_SHOW, eventProperty)
  }

  logLoginCompleted({
    source,
    email,
    userId,
    method,
  }: {
    source: string
    email: string
    userId: string
    method: string
  }): void {
    const eventProperty = {
      user_ID: userId,
      source,
      email,
      method,
    }
    this.logEventOrPushToQueue(Events.LOGIN_COMPLETED, eventProperty)
  }

  logLoginFailed({
    error,
    source,
    email,
    method,
  }: {
    error: any
    source: string
    email: string
    method: string
  }): void {
    const eventProperty = { error, method, source, email }
    this.logEventOrPushToQueue(Events.LOGIN_FAILED, eventProperty)
  }

  logSubscriptionDetailsShow({
    source,
    email,
    userId,
    method,
  }: {
    source: string
    email: string
    userId: string
    method: string
  }): void {
    const eventProperty = {
      user_ID: userId,
      source,
      email,
      method,
    }
    this.logEventOrPushToQueue(Events.SUBSCRIPTION_DETAILS_SHOW, eventProperty)
  }

  logCancelSubscriptionFailed({
    userId,
    error,
    source,
    email,
    method,
  }: {
    userId: string
    error: string
    source: string
    email: string
    method: string
  }): void {
    const eventProperty = {
      user_ID: userId,
      error,
      source,
      email,
      method,
    }
    this.logEventOrPushToQueue(Events.CANCEL_SUBSCRIPTION_FAILED, eventProperty)
  }

  logSubscriptionCancelled({
    userId,
    productId,
    price,
    paymentMethod,
    source,
    email,
    method,
  }: {
    userId: string
    productId: string
    price: string
    paymentMethod: string
    source: string
    email: string
    method: string
  }): void {
    const eventProperty = {
      user_ID: userId,
      product_id: productId,
      price,
      payment_method: paymentMethod,
      source,
      email,
      method,
    }
    this.logEventOrPushToQueue(Events.SUBSCRIPTION_CANCELLED, eventProperty)
  }

  logTermsOfUseClicked(source: string, userId?: string): void {
    const event = Events.TERMS_OF_USE
    const eventProperty = {
      source,
      ...(userId && { user_ID: userId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logOpenApp(userId: string): void {
    const event = Events.OPEN_APP
    const eventProperty = { user_ID: userId }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPrivacyPolicyClicked(source: string, userId?: string): void {
    const event = Events.PRIVACY_POLICY
    const eventProperty = {
      source,
      ...(userId && { user_ID: userId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logContactSupportClicked(source: string, userId?: string): void {
    const event = Events.CONTACT_SUPPORT
    const eventProperty = {
      source,
      ...(userId && { user_ID: userId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logHelpCenterClicked(source: string, userId?: string): void {
    const event = Events.HELP_CENTER
    const eventProperty = {
      source: EVENT_SOURCE.ACCOUNT_PROFILE,
      ...(userId && { user_ID: userId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logResetLinkClicked(source: string): void {
    const event = Events.RESET_LINK
    const eventProperty = {
      source,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logSupportVidgetClicked(source: string): void {
    const event = Events.SUPPORT_VIDGET_TAP
    const eventProperty = {
      source,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logResetPageClosed(source: string): void {
    const event = Events.RESET_PAGE_CLOSED
    const eventProperty = {
      source,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logForgotPassword(source: string): void {
    const event = Events.FORGOT_PASSWORD
    const eventProperty = {
      source,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  private logEventOrPushToQueue(
    event: Events,
    eventProperty?: Record<string, any>,
  ): void {
    if (this.loggers?.size) {
      this.logEvent({
        event,
        eventProperty,
      })
    } else {
      this.eventsQueue.push({ event, eventProperty })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent({
          event,
          eventProperty,
        }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({
    event,
    eventProperty,
  }: {
    event: Events
    eventProperty?: Record<string, any>
  }): void {
    this.loggers?.forEach((logger) => {
      logger.log(event, eventProperty)
    })
  }
}

export const eventLogger = new EventLoggerService()
