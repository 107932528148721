import styled from 'styled-components'

export const StyledOuterLinkIframe = styled.iframe`
  position: fixed;
  background-color: white;
  width: 100vw;
  height: var(--full-height, 100vh);
  top: 0;
  left: 0;
  z-index: 10;
`
