import { IAppState } from 'models/store.model'

export const selectFirebaseErrorMessage = (state: IAppState): string =>
  state.unsubscribeLogin.emailErrorMessage

export const selectFirebaseInfoMessage = (state: IAppState): string =>
  state.unsubscribeLogin.emailInfoMessage

export const selectIsPasswordReset = (state: IAppState): boolean =>
  state.unsubscribeLogin.isPasswordReset
