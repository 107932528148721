import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setSourceAction } from 'root-redux/actions/common'

import { Source } from 'root-constants'

export const useLinkSourceFromUrl = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    const { search } = window.location
    const query = new URLSearchParams(search)
    const source = query.get('source') || Source.APP

    dispatch(setSourceAction(source))
  }, [dispatch])
}
