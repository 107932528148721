import { composeWithDevTools } from '@redux-devtools/extension'
import {
  AnyAction,
  Store,
  applyMiddleware,
  legacy_createStore as createStore,
} from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'

import { IAction, IAppState } from 'models/store.model'

import { rootReducer } from './rootReducer'

export type TAppStore = Store<IAppState>
export type TAppDispatch = ThunkDispatch<IAppState, any, AnyAction>

export const enhancer = composeWithDevTools(applyMiddleware(thunk))
export const store: TAppStore = createStore<IAppState, IAction<any>, any, any>(
  rootReducer,
  enhancer,
)
