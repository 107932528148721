import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getApps, initializeApp } from 'firebase/app'
import { getAuth, signOut } from 'firebase/auth'

import { selectFirebaseConfig } from 'root-redux/selects/common'

export const useInitFirebase = (): void => {
  const firebaseConfig = useSelector(selectFirebaseConfig)

  useEffect(() => {
    if (firebaseConfig && !getApps.length) {
      initializeApp(firebaseConfig)
      const auth = getAuth()
      signOut(auth)
    }
  }, [firebaseConfig])
}
