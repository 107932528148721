import { AnyAction } from 'redux'

import {
  RESET_FIREBASE_ERROR_MESSAGE,
  RESET_FIREBASE_INFO_MESSAGE,
  SET_FIREBASE_ERROR_MESSAGE,
  SET_FIREBASE_INFO_MESSAGE,
  SET_IS_PASSWORD_RESET,
} from './actions'

export interface IUnsubscribeLoginState {
  isPasswordReset: boolean
  emailErrorMessage: string
  emailInfoMessage: string
}

const initialState: IUnsubscribeLoginState = {
  isPasswordReset: false,
  emailErrorMessage: '',
  emailInfoMessage: '',
}

export function unsubscribeLoginReducer(
  state = initialState,
  { type, payload }: AnyAction,
): IUnsubscribeLoginState {
  switch (type) {
    case SET_FIREBASE_ERROR_MESSAGE: {
      return { ...state, emailErrorMessage: payload }
    }
    case SET_FIREBASE_INFO_MESSAGE: {
      return { ...state, emailInfoMessage: payload }
    }
    case RESET_FIREBASE_ERROR_MESSAGE: {
      return { ...state, emailErrorMessage: '' }
    }
    case RESET_FIREBASE_INFO_MESSAGE: {
      return { ...state, emailInfoMessage: '' }
    }
    case SET_IS_PASSWORD_RESET: {
      return { ...state, isPasswordReset: payload }
    }
    default:
      return state
  }
}
