import React, {
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import { selectActionList } from 'root-redux/selects/common'

import { UPLOAD_FILES, selectUploadedFiles } from 'modules/contactForm/redux'

import clipIcon from 'assets/images/sprite/clip-icon.svg'
import errorIcon from 'assets/images/sprite/error-icon.svg'
import imageIcon from 'assets/images/sprite/image-type-file.svg'

import { Color } from 'root-constants'

import { StyledAttachmentsReactHook as S } from './Attachments.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  files: File[]
  setAttachmentsError: Dispatch<SetStateAction<string>>
  onDeleteFile: (fileId: string) => void
  labelName: string
  validationText?: string
}

export const Attachments: React.FC<TProps> = ({
  files,
  setAttachmentsError,
  onDeleteFile,
  labelName,
  validationText,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const uploadedFiles = useSelector(selectUploadedFiles)
  const fetchingActionsList = useSelector(selectActionList)

  const areFilesUploading = useMemo(
    () => fetchingActionsList.includes(UPLOAD_FILES),
    [fetchingActionsList],
  )

  useEffect(() => {
    if (inputRef.current) {
      const dataTransfer = new DataTransfer()
      files.forEach((file) => dataTransfer.items.add(file))
      inputRef.current.files = dataTransfer.files
    }
  }, [files])

  return (
    <S.Wrapper>
      <S.Label>
        {labelName}
        {files.length ? ` (${files.length})` : ''}
      </S.Label>
      <S.InputContainer>
        <S.FakeInputButton>
          <S.ClipIcon svg={clipIcon} />
          <S.FakeInputText>Add up to 3 files</S.FakeInputText>
          {areFilesUploading && (
            <S.SpinnerContainer>
              <Spinner isFullScreen={false} height={40} color={Color.GRAY} />
            </S.SpinnerContainer>
          )}
        </S.FakeInputButton>
        <S.Input
          type="file"
          accept="image/*,video/*"
          multiple
          ref={inputRef}
          {...props}
        />
      </S.InputContainer>
      {!!files.length && (
        <S.FilesContainer>
          {files.map(({ name, lastModified }, index) => (
            <S.File key={`${name}_${lastModified}`}>
              <S.CloseButton
                onClick={() => onDeleteFile(`${name}_${lastModified}`)}
              />
              <S.FileTypeIcon
                svg={uploadedFiles[index]?.hasError ? errorIcon : imageIcon}
              />
              <S.FileName hasError={!!uploadedFiles[index]?.hasError}>
                {name}
                {!!uploadedFiles[index]?.hasError && (
                  <S.FileUploadErrorText>
                    {' '}
                    (File upload failed)
                  </S.FileUploadErrorText>
                )}
              </S.FileName>
            </S.File>
          ))}
        </S.FilesContainer>
      )}

      {validationText && (
        <S.ErrorContainer>
          <S.ErrorText>{validationText}</S.ErrorText>
          <S.CloseButton onClick={() => setAttachmentsError('')} />
        </S.ErrorContainer>
      )}
    </S.Wrapper>
  )
}
