import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import closeIcon from 'assets/images/close-icon.svg'

import { Color } from 'root-constants'

type TFileNameProps = {
  hasError: boolean
}

export const StyledAttachmentsReactHook = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    min-width: 280px;
    margin-bottom: 20px;

    &::after {
      display: block;
      position: absolute;
      top: 104px;
      left: 16px;
      color: ${Color.ERROR};
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    &[data-valid='false']::after {
      content: attr(data-validation-text);
    }
  `,
  InputContainer: styled.div`
    width: 100%;
    height: 100px;
    background-color: ${Color.WHITE};
    border-radius: 10px;
    padding: 34px 16px 8px;

    &[data-valid='false'] {
      border: 1px solid ${Color.ERROR};
    }
  `,
  FakeInputButton: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 2px dashed rgb(216, 220, 222);

    &[data-valid='false'] {
      border: 2px dashed ${Color.ERROR};
    }
  `,
  FakeInputText: styled.p`
    color: ${Color.GRAY};
    font-size: 13px;
  `,
  ClipIcon: styled(SvgImage)`
    width: 20px;
    height: auto;
    margin-right: 6px;
  `,
  SpinnerContainer: styled.div`
    position: absolute;
    top: 2px;
    right: 12px;
  `,
  Input: styled.input`
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100px;
    outline: none;
    opacity: 0;
    cursor: pointer;
    color: red;
  `,
  Label: styled.span`
    position: absolute;
    top: 18px;
    transform: translateY(-50%);
    left: 16px;
    color: ${Color.GRAY};
    font-size: 14px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  FilesContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
    gap: 10px;
  `,
  File: styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 8px 20px 8px 12px;
    background-color: ${Color.WHITE};
    border-radius: 10px;
    font-size: 13px;
  `,
  FileName: styled.p<TFileNameProps>`
    color: ${({ hasError }) => (hasError ? Color.ERROR : Color.DARK)};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  FileUploadErrorText: styled.span`
    color: ${Color.ERROR};
  `,
  FileTypeIcon: styled(SvgImage)`
    min-width: 20px;
    width: 20px;
    height: auto;
    margin-right: 10px;
  `,
  ErrorContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    padding: 8px 20px 8px 12px;
    background-color: #f5b5ba;
    border-radius: 10px;
  `,
  ErrorText: styled.p`
    font-size: 13px;
    color: #8c232c;
  `,
  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 5px;
    right: 7px;
    width: 16px;
    height: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 12px 12px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeIcon});
  `,
}
