import { Link } from 'react-router-dom'

import styled from 'styled-components'

import { Color } from 'root-constants'

type TButtonProps = {
  color: string
}

export const StyledContactSupport = {
  Question: styled.p`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;

    &:not(:first-child) {
      margin-top: 10px;
    }
  `,
  SupportLink: styled(Link)<TButtonProps>`
    color: ${Color.LINK_COLOR};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline;
    cursor: pointer;
  `,
  HelpCenterLink: styled.a`
    color: ${Color.LINK_COLOR};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline;
    cursor: pointer;
  `,
}
