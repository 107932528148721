import { configApi } from 'api'

import { getAppConfigFromConfigRaw } from 'helpers/getAppConfigFromConfigRaw'

import { IAppConfig } from 'models/config.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'

const MODULE_NAME = 'COMMON'

// actions types
export const SET_APP_NAME = `${MODULE_NAME}/SET_APP_NAME`
export const START_FETCHING = `${MODULE_NAME}/START_FETCHING`
export const STOP_FETCHING = `${MODULE_NAME}/STOP_FETCHING`
export const SET_ERROR = `${MODULE_NAME}/SET_ERROR`
export const RESET_ERROR = `${MODULE_NAME}/RESET_ERROR`
export const GET_APP_CONFIG = `${MODULE_NAME}/GET_APP_CONFIG`
export const SET_APP_CONFIG = `${MODULE_NAME}/SET_APP_CONFIG`
export const SET_SOURCE = `${MODULE_NAME}/SET_SOURCE`

// actions handlers

export function setAppNameAction(action: string): IAction<string> {
  return {
    type: SET_APP_NAME,
    payload: action,
  }
}

export function startFetching(action: string): IAction<string> {
  return {
    type: START_FETCHING,
    payload: action,
  }
}

export function stopFetching(actionToStop: string): any {
  return (dispatch: TAppDispatchThunk<string[]>, getState: () => IAppState) => {
    const runningActions = getState().common.actionList
    const fetchList = runningActions.filter(
      (action: string) => action && action !== actionToStop,
    )

    dispatch({
      type: STOP_FETCHING,
      payload: fetchList,
    })
  }
}

export function setErrorAction<T>(error: T): IAction<T> {
  return {
    type: SET_ERROR,
    payload: error,
  }
}

export function resetErrorAction(): IAction<never> {
  return {
    type: RESET_ERROR,
  }
}

export function setAppConfigAction(payload: IAppConfig): IAction<IAppConfig> {
  return {
    type: SET_APP_CONFIG,
    payload,
  }
}

export function getAppConfigAction(appName: string): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_APP_CONFIG))

    const response = await configApi.getAppConfig(appName)

    if (response.success && response.data) {
      const config = getAppConfigFromConfigRaw(response.data.config)
      dispatch(setAppConfigAction(config))
    }

    dispatch(stopFetching(GET_APP_CONFIG))
  }
}

export function setSourceAction(action: string): IAction<string> {
  return {
    type: SET_SOURCE,
    payload: action,
  }
}
