import React from 'react'

import { Header } from 'components/Header'

import { useInitFirebase } from 'hooks/useInitFirebase'

import { ResetPassword } from 'modules/unsubscribeResetPassword/components/ResetPassword'

import { StyledUnsubscribeResetPassword as S } from './UnsubscribeResetPassword.styles'

export const UnsubscribeResetPassword: React.FC = () => {
  useInitFirebase()

  return (
    <S.Wrapper>
      <Header />
      <ResetPassword />
    </S.Wrapper>
  )
}
