import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserSubscriptionInfo } from 'root-redux/selects/user'

import { getBillingCycleKey } from 'modules/unsubscribe/helpers'

import { SubscriptionContainer as Container } from 'common-styles'
import { CURRENCY_SYMBOLS } from 'root-constants'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import { FREE_EXTRA_PROGRAMS, TSubscriptionDetailsProps } from './constants'

export const PausedSubscriptionDetails: React.FC<TSubscriptionDetailsProps> = ({
  extra,
}: TSubscriptionDetailsProps) => {
  const { t } = useTranslation()
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)

  if (!userSubscriptionInfo) {
    return null
  }

  const trialPrice = extra
    ? extra.trialPrice
    : userSubscriptionInfo.trialSubAmount || userSubscriptionInfo.trialPrice

  const trialDays = extra?.trialDaysCount || userSubscriptionInfo.trialDaysCount

  const currency = CURRENCY_SYMBOLS[userSubscriptionInfo.currency.toLowerCase()]

  const isFreeExtraProgram =
    extra && FREE_EXTRA_PROGRAMS.includes(extra.productId)

  return (
    <>
      <S.SubscriptionRow>
        <span>{t('subscription.pauseDate')}</span>
        <Container.InfoValue>
          {userSubscriptionInfo.pausedAt}
        </Container.InfoValue>
      </S.SubscriptionRow>
      {userSubscriptionInfo.isOnTrialPeriod && (
        <>
          {!!trialDays && (
            <S.SubscriptionRow>
              <span>{t('subscription.trialPeriod')}</span>
              <Container.InfoValue>
                {t(
                  `subscription.trialBillingPeriods.${getBillingCycleKey(
                    trialDays,
                  )}`,
                  {
                    count: trialDays,
                  },
                )}
              </Container.InfoValue>
            </S.SubscriptionRow>
          )}
          {(!!trialPrice || isFreeExtraProgram) && (
            <S.SubscriptionRow>
              <span>{t('subscription.trialPrice')}</span>
              <Container.InfoValue>
                {t('subscription.priceAmount', {
                  currency,
                  amount: trialPrice,
                })}
              </Container.InfoValue>
            </S.SubscriptionRow>
          )}
        </>
      )}
    </>
  )
}
