import { AppWithInAppBrowser } from 'root-constants'

export function getAppNameFromInAppBrowser(): AppWithInAppBrowser | null {
  const { userAgent } = window.navigator

  if (/instagram/i.test(userAgent)) {
    return AppWithInAppBrowser.INSTAGRAM
  }

  if (/FBAN|FBAV/i.test(userAgent)) {
    return AppWithInAppBrowser.FACEBOOK
  }

  return null
}
