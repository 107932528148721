import React from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import { TDropdownValue } from 'models/common.model'

import { StyledSelectReactHook as S } from './Select.styles'

type TProps = {
  register: UseFormRegisterReturn<string>
  options: TDropdownValue[]
  labelName: string
  isValid: boolean
  validationText: string
}

export const Select: React.FC<TProps> = ({
  register,
  options,
  labelName,
  isValid,
  validationText,
}) => {
  return (
    <S.Wrapper data-valid={isValid} data-validation-text={validationText}>
      <S.Select {...register}>
        {options.map(({ value, name }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </S.Select>
      <S.Label>{labelName}</S.Label>
    </S.Wrapper>
  )
}
