import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import {
  APP_COLORS,
  CONTACT_FORM_LINK,
  EVENT_SOURCE,
  SUPPORT_FORM_LINKS,
} from 'root-constants'

import { StyledContactSupport as S } from './ContactSupport.styles'

export const ContactSupport: React.FC<{
  showHelpCenterLink?: boolean
  source?: EVENT_SOURCE
}> = ({ showHelpCenterLink = true, source = EVENT_SOURCE.ACCOUNT_PROFILE }) => {
  const appName = useSelector(selectAppName)
  const userId = useSelector(selectUUID)
  const { t } = useTranslation()

  const handleContactUsButtonClick = useCallback(() => {
    eventLogger.logContactSupportClicked(source, userId)
  }, [source, userId])

  const handleHelpCenterButtonClick = useCallback(() => {
    eventLogger.logHelpCenterClicked(userId)
  }, [userId])

  if (SUPPORT_FORM_LINKS[appName] && showHelpCenterLink) {
    return (
      <S.Question>
        <Trans
          i18nKey="login.headToHelpCenter"
          components={{
            link: (
              <S.HelpCenterLink
                target="_blank"
                href={SUPPORT_FORM_LINKS[appName]}
                onClick={handleHelpCenterButtonClick}
              >
                {t('login.helpCenter')}
              </S.HelpCenterLink>
            ),
          }}
        />
      </S.Question>
    )
  }

  return (
    <S.Question>
      {t('login.haveQuestion')}{' '}
      <S.SupportLink
        target="_blank"
        to={CONTACT_FORM_LINK}
        color={APP_COLORS[appName]}
        onClick={handleContactUsButtonClick}
      >
        {t('login.contactSupport')}
      </S.SupportLink>
    </S.Question>
  )
}
