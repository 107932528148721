import React, {
  InputHTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react'

import blackCheckIconSvg from 'assets/images/sprite/black-check.svg'
import closedEye from 'assets/images/sprite/closed-eye.svg'
import openedEye from 'assets/images/sprite/opened-eye.svg'

import { StyledInputWithFloatPlaceholder as S } from './InputWithFloatPlaceholder.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  type?: string
  labelName: string
  isValid?: boolean
  hasVisibilityControl?: boolean
  validationText?: string
  typeTextIcon?: string
  typePasswordIcon?: string
  marginBottom?: number
}

export const InputWithFloatPlaceholder: React.FC<TProps> = ({
  value,
  type = 'text',
  labelName,
  isValid = true,
  hasVisibilityControl = false,
  validationText = '',
  typeTextIcon = openedEye,
  typePasswordIcon = closedEye,
  marginBottom = 0,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const inputType = useMemo(() => {
    if (!hasVisibilityControl) return type

    return isPasswordVisible ? 'text' : 'password'
  }, [hasVisibilityControl, isPasswordVisible, type])

  const togglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible((prevState) => !prevState)
  }, [])

  return (
    <S.Wrapper data-valid={isValid}>
      <S.Input required type={inputType} value={value} {...props} />
      <S.Label>{labelName}</S.Label>

      {!hasVisibilityControl && value && isValid && (
        <S.SvgIcon svg={blackCheckIconSvg} />
      )}

      {hasVisibilityControl && isPasswordVisible && (
        <S.Icon svg={typeTextIcon} onClick={togglePasswordVisibility} />
      )}

      {hasVisibilityControl && !isPasswordVisible && (
        <S.Icon svg={typePasswordIcon} onClick={togglePasswordVisibility} />
      )}

      <S.ValidationText
        minHeight={marginBottom}
        isVisible={Boolean(!isValid && validationText)}
      >
        {validationText}
      </S.ValidationText>
    </S.Wrapper>
  )
}
