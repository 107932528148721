import styled, { css } from 'styled-components'

import backIcon from 'assets/images/back-arrow.svg'
import appleIcon from 'assets/images/continue-with-apple.svg'
import facebookIcon from 'assets/images/continue-with-facebook.svg'
import googleIcon from 'assets/images/continue-with-google.svg'

import { Color } from 'root-constants'

export type TButtonProps = {
  backgroundColor?: string
}

type TContainerProps = {
  backgroundColor?: string
}

export const StyledButtonBase = css<TButtonProps>`
  width: 100%;
  max-width: 320px;
  height: 56px;
  display: block;
  padding: 0 13px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 30px;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${Color.WHITE};
  transition: background-color 0.2s ease-out;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

const StyledLoginButtonBase = css`
  ${StyledButtonBase};
  width: 100%;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  background-repeat: no-repeat;
  background-position: 30px center;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const StyledLoginButton = {
  Email: styled.button`
    ${StyledLoginButtonBase};
  `,
  Google: styled.button`
    ${StyledLoginButtonBase};
    padding: 0 16px;
    height: 56px;
    border: 1px solid #bdbdbe;
    border-radius: 40px;
    background-color: ${Color.WHITE};
    background-image: url(${googleIcon});
    color: #0a324d;
  `,
  Facebook: styled.button`
    ${StyledLoginButtonBase};
    padding: 0 16px;
    height: 56px;
    border-radius: 40px;
    background-image: url(${facebookIcon});
    background-color: #395a98;
  `,
  Apple: styled.button`
    ${StyledLoginButtonBase};
    padding: 0 16px;
    height: 56px;
    border-radius: 40px;
    background-image: url(${appleIcon});
    background-color: #041a28;
  `,
}

export const BackButton = styled.div`
  position: absolute;
  top: 68px;
  left: 16px;
  width: 24px;
  height: 24px;
  background-image: url(${backIcon});
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
`

export const SubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(var(--full-height, 100vh) - 61px);
  padding-bottom: 24px;
`

export const SubscriptionTitle = styled.h1`
  margin-bottom: 24px;
  color: ${Color.BLACK};
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
`

export const SubscriptionContainer = styled.div<TContainerProps>`
  padding: 24px;
  border-radius: 20px;
  border: 1px solid #f2f3f5;
  background-color: ${({ backgroundColor }) => backgroundColor || Color.WHITE};
  box-shadow: ${({ backgroundColor }) =>
    backgroundColor ? 'none' : '0 8px 20px 0 #e8e8e8'};

  & > div:first-of-type {
    padding-top: 16px;
    border-top: 1px solid #f2f3f5;
  }

  & > div:last-of-type {
    padding-bottom: 16px;
    border-bottom: 1px solid #f2f3f5;
  }
`
