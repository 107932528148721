import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import i18n from 'i18next'

import { startFetching, stopFetching } from 'root-redux/actions/common'
import { selectSource } from 'root-redux/selects/common'

import { setLoginMethodToLocalStorage } from 'helpers/setLoginMethodToLocalStorage'

import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'

import { eventLogger } from 'services/eventLogger.service'

import { FIREBASE_ERRORS, LoginMethod } from 'root-constants'

const MODULE_NAME = 'UNSUBSCRIBE_LOGIN'

export const LOGIN_WITH_EMAIL_FIREBASE = `${MODULE_NAME}/LOGIN_WITH_EMAIL_FIREBASE`
export const RESET_PASSWORD = `${MODULE_NAME}/RESET_PASSWORD`
export const SET_IS_PASSWORD_RESET = `${MODULE_NAME}/SET_IS_PASSWORD_RESET`
export const SET_FIREBASE_ERROR_MESSAGE = `${MODULE_NAME}/SET_FIREBASE_ERROR_MESSAGE`
export const RESET_FIREBASE_ERROR_MESSAGE = `${MODULE_NAME}/RESET_FIREBASE_ERROR_MESSAGE`
export const SET_FIREBASE_INFO_MESSAGE = `${MODULE_NAME}/SET_FIREBASE_INFO_MESSAGE`
export const RESET_FIREBASE_INFO_MESSAGE = `${MODULE_NAME}/RESET_FIREBASE_INFO_MESSAGE`

export const resetFirebaseErrorMessageAction = (): IAction<never> => ({
  type: RESET_FIREBASE_ERROR_MESSAGE,
})

export const setFirebaseErrorMessageAction = (
  message: string,
): IAction<string> => ({
  type: SET_FIREBASE_ERROR_MESSAGE,
  payload: message,
})

export const resetFirebaseInfoMessageAction = (): IAction<never> => ({
  type: RESET_FIREBASE_INFO_MESSAGE,
})

export const setFirebaseInfoMessageAction = (
  message: string,
): IAction<string> => ({
  type: SET_FIREBASE_INFO_MESSAGE,
  payload: message,
})

export const setIsPasswordResetAction = (value: boolean): IAction<boolean> => ({
  type: SET_IS_PASSWORD_RESET,
  payload: value,
})

export const loginWithEmailFirebaseAction =
  (email: string, password: string): any =>
  async (dispatch: TAppDispatchThunk<any>, getState: () => IAppState) => {
    const state = getState()
    const source = selectSource(state)

    dispatch(startFetching(LOGIN_WITH_EMAIL_FIREBASE))
    setLoginMethodToLocalStorage(LoginMethod.EMAIL)

    try {
      const auth = getAuth()
      await signInWithEmailAndPassword(auth, email, password)

      setLoginMethodToLocalStorage(LoginMethod.EMAIL)
      dispatch(stopFetching(LOGIN_WITH_EMAIL_FIREBASE))
    } catch (error: any) {
      eventLogger.logLoginFailed({
        error: error.code,
        source,
        email,
        method: LoginMethod.EMAIL,
      })

      if (Object.values(FIREBASE_ERRORS).includes(error.code)) {
        dispatch(setFirebaseErrorMessageAction(i18n.t('login.error.common')))
      }

      dispatch(stopFetching(LOGIN_WITH_EMAIL_FIREBASE))
    }
  }

export const resetUserPassword =
  (email: string): TAppDispatchThunk<any> =>
  async (dispatch) => {
    dispatch(startFetching(RESET_PASSWORD))

    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)

      dispatch(setIsPasswordResetAction(true))
      dispatch(stopFetching(RESET_PASSWORD))
    } catch (error: any) {
      if (error.code === FIREBASE_ERRORS.USER_NOT_FOUND) {
        dispatch(
          setFirebaseErrorMessageAction(i18n.t('login.error.userNotFound')),
        )
      }
      dispatch(stopFetching(RESET_PASSWORD))
    }
  }
