import styled from 'styled-components'

import { Header } from 'components/Header'

type TFormProps = {
  backgroundColor: string
}

export const StyledContactForm = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(var(--full-height, 100vh) - 61px);
    padding-bottom: 24px;
  `,
  Header: styled(Header)`
    box-shadow: none;
  `,
  Title: styled.h1`
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
  `,
  Form: styled.form<TFormProps>`
    margin-bottom: 34px;
    padding: 20px 16px 24px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 16px;
  `,
  InputContainer: styled.div`
    position: relative;
  `,
  Attachments: styled.img`
    max-width: 180px;
    max-height: 100px;
    width: 100%;
  `,
  CaptchaContainer: styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  `,
}
