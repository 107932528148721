import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledDetailsTab as S } from './DetailsTab.styles'

export const enum TABS {
  ACCOUNT = 'account',
  SUBSCRIPTIONS = 'subscriptions',
}

type TProps = {
  activeTab: TABS
  handleChange: (event: TABS) => void
}

export const DetailsTab: React.FC<TProps> = ({ activeTab, handleChange }) => {
  const { t } = useTranslation()

  return (
    <S.TabsContainer>
      <S.Tab
        isActive={TABS.ACCOUNT === activeTab}
        onClick={() => handleChange(TABS.ACCOUNT)}
      >
        {t('subscription.tabs.account')}
      </S.Tab>
      <S.Tab
        isActive={TABS.SUBSCRIPTIONS === activeTab}
        onClick={() => handleChange(TABS.SUBSCRIPTIONS)}
      >
        {t('subscription.tabs.subscriptions')}
      </S.Tab>
    </S.TabsContainer>
  )
}
