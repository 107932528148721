import { IAppConfig, IFirebaseConfig, TGiaApiKeys } from 'models/config.model'
import { IAppState } from 'models/store.model'

export const selectAppName = (state: IAppState): string => state.common.appName

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching

export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList

export const selectError = (state: IAppState): any => state.common.error

export const selectAppConfig = (state: IAppState): IAppConfig | null =>
  state.common.appConfig

export const selectFirebaseConfig = (
  state: IAppState,
): IFirebaseConfig | null => state.common.appConfig?.firebaseConfig || null

export const selectGiaApiKeys = (state: IAppState): TGiaApiKeys | null =>
  state.common.appConfig?.giaApiKeys || null

export const selectSource = (state: IAppState): string => state.common.source
