import { AnyAction } from 'redux'

import { IUserSubscriptionsInfo } from 'models/commonApi.model'

import {
  SET_AUTH_TOKEN,
  SET_AUTOLOGIN_TOKEN,
  SET_USER_CONTACT_EMAIL,
  SET_USER_NAME,
  SET_USER_SUBSCRIPTION_INFO,
  SET_UUID,
} from '../actions/user'

export interface IUserState {
  authToken: string
  userSubscriptionsInfo: IUserSubscriptionsInfo | null
  userContactEmail: string
  userName: string
  uuid: string
  autologinToken: string
}

const initState: IUserState = {
  authToken: '',
  userSubscriptionsInfo: null,
  userContactEmail: '',
  userName: '',
  uuid: '',
  autologinToken: '',
}

export function userReducer(
  state: IUserState = initState,
  { type, payload = null }: AnyAction,
): IUserState {
  switch (type) {
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: payload,
      }
    }
    case SET_USER_SUBSCRIPTION_INFO: {
      return {
        ...state,
        userSubscriptionsInfo: payload,
      }
    }
    case SET_USER_CONTACT_EMAIL: {
      return {
        ...state,
        userContactEmail: payload,
      }
    }
    case SET_USER_NAME: {
      return {
        ...state,
        userName: payload,
      }
    }
    case SET_UUID: {
      return {
        ...state,
        uuid: payload,
      }
    }
    case SET_AUTOLOGIN_TOKEN: {
      return {
        ...state,
        autologinToken: payload,
      }
    }
    default:
      return state
  }
}
