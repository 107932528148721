import { IUserSubscriptionsInfo } from 'models/commonApi.model'
import { IAppState } from 'models/store.model'

export const selectAuthToken = (state: IAppState): string =>
  state.user.authToken

export const selectUserSubscriptionInfo = (
  state: IAppState,
): IUserSubscriptionsInfo | null => state.user.userSubscriptionsInfo

export const selectUserContactEmail = (state: IAppState): string =>
  state.user.userContactEmail

export const selectUserName = (state: IAppState): string => state.user.userName

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectAutologinToken = (state: IAppState): string =>
  state.user.autologinToken
