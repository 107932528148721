import React, { InputHTMLAttributes } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import { StyledInputHookForm as S } from './InputHookForm.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  register: UseFormRegisterReturn<string>
  labelName: string
  isValid?: boolean
  validationText?: string
  marginBottom?: number
}

export const InputHookForm: React.FC<TProps> = ({
  register,
  labelName,
  isValid,
  validationText,
  marginBottom = 0,
  ...props
}) => {
  return (
    <S.Wrapper
      data-valid={isValid}
      data-validation-text={validationText}
      marginBottom={marginBottom}
    >
      <S.Input {...register} {...props} autoComplete="off" />
      <S.Label>{labelName}</S.Label>
    </S.Wrapper>
  )
}
