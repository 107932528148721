import cardimateIcon from 'assets/images/cardimate-icon.png'
import cardimateLogo from 'assets/images/cardimate-logo.png'
import dancebitIcon from 'assets/images/dancebit-icon.png'
import dancebitLogo from 'assets/images/dancebit-logo.png'
import famioIcon from 'assets/images/famio-icon.png'
import famioLogo from 'assets/images/famio-logo.png'
import fitmeIcon from 'assets/images/fitme-icon.png'
import fitmeLogo from 'assets/images/fitme-logo.png'
import luvlyIcon from 'assets/images/luvly-icon.png'
import luvlyLogo from 'assets/images/luvly-logo.png'
import meowz8Logo from 'assets/images/meowz-logo.svg'
import nove8Icon from 'assets/images/nove8-icon.png'
import nutrimateIcon from 'assets/images/nutrimate-icon.png'
import nutrimateLogo from 'assets/images/nutrimate-logo.png'
import upluvIcon from 'assets/images/upluv-icon.png'
import upluvLogo from 'assets/images/upluv-logo.png'
import woofzIcon from 'assets/images/woofz-icon.png'

import { AppName } from 'root-constants'

export const APP_LOGOS = {
  [AppName.LUVLY]: { image: luvlyLogo, width: 66 },
  [AppName.DANCEBIT]: { image: dancebitLogo, width: 54, name: 'Dancebit' },
  [AppName.NUTRIMATE]: { image: nutrimateLogo, width: 114 },
  [AppName.FAMIO]: { image: famioLogo, width: 100 },
  [AppName.UPLUV]: { image: upluvLogo, width: 57 },
  [AppName.WOOFZ]: { image: woofzIcon, width: 36 },
  [AppName.MEOWZ]: {
    image: meowz8Logo,
    width: 36,
    name: 'Meowz: Cat Training & Care',
  },
  [AppName.CARDIMATE]: { image: cardimateLogo, width: 121 },
  [AppName.FITME]: { image: fitmeLogo, width: 58 },
}

export const BANNER_LOGOS = {
  [AppName.LUVLY]: {
    image: luvlyIcon,
    name: 'Luvly',
    bgColor: 'linear-gradient(270deg, #99A7CD 0%, #D09EA1 100%)',
  },
  [AppName.DANCEBIT]: {
    image: dancebitIcon,
    name: 'Dancebit',
    bgColor: 'linear-gradient(90deg, #45B5FF 0%, #3B91FF 100%)',
  },
  [AppName.NUTRIMATE]: {
    image: nutrimateIcon,
    name: 'NutriMate',
    bgColor: 'linear-gradient(90deg, #9ACA42 0%, #87BD34 100%)',
  },
  [AppName.FAMIO]: {
    image: famioIcon,
    name: 'Famio',
    bgColor: 'linear-gradient(90deg, #9388FF 0%, #6336FF 100%)',
  },
  [AppName.UPLUV]: {
    image: upluvIcon,
    name: 'UpLuv',
    bgColor: 'linear-gradient(90deg, #71C6FF 0%, #D8A9ED 100%)',
  },
  [AppName.WOOFZ]: {
    image: woofzIcon,
    name: 'Woofz',
    bgColor: '#f5e8df',
  },
  [AppName.MEOWZ]: {
    image: nove8Icon,
    name: 'Nove8',
    bgColor: '#fff',
  },
  [AppName.CARDIMATE]: {
    image: cardimateIcon,
    name: 'Cardi Mate',
    bgColor: 'linear-gradient(270deg, #B4CDFB 0%, #94B6F0 100%)',
  },
  [AppName.FITME]: {
    image: fitmeIcon,
    name: 'FitMe',
    bgColor: 'linear-gradient(90deg, #6357FF 0%, #8B81FE 100%)',
  },
}
