import React, { TextareaHTMLAttributes } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import { StyledTextAreaReactHook as S } from './Textarea.styles'

type TProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  register: UseFormRegisterReturn<string>
  labelName: string
  isValid: boolean
  validationText: string
}

export const Textarea: React.FC<TProps> = ({
  register,
  labelName,
  isValid,
  validationText,
  ...props
}) => {
  return (
    <S.Wrapper data-valid={isValid} data-validation-text={validationText}>
      <S.TextArea {...register} {...props} />
      <S.Label>{labelName}</S.Label>
    </S.Wrapper>
  )
}
