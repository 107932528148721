import styled from 'styled-components'

import { StyledButtonBase, TButtonProps } from 'common-styles'

export const StyledButton = styled.button<TButtonProps>`
  ${StyledButtonBase};

  &:disabled {
    background-color: #cacaca;
  }
`
