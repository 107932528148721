import { getDateFromTimeStamp } from 'helpers/date'
import { roundToNearestTenth } from 'helpers/roundToNearestTenth'

import {
  IRawUserSubscriptionsInfo,
  IUserSubscriptionsInfo,
} from 'models/commonApi.model'

import { CENTS_IN_DOLLAR, FIXED_TERM_TRIAL_CYCLES } from 'root-constants'

export const getActiveUserSubscriptionFromRawUserSubscriptions = (
  rawUserSubscriptionsInfo: IRawUserSubscriptionsInfo[],
): IUserSubscriptionsInfo => {
  const [
    {
      id: subscriptionId,
      current_period_start: startDate,
      current_period_end: endDate,
      status,
      trial: {
        amount: trialAmount,
        start: trialStartDate,
        end: trialEndDate,
        days_count: trialDaysCount,
      },
      recurring: { interval_count: intervalCount, amount, currency, interval },
    },
  ] = rawUserSubscriptionsInfo.sort((a, b) => +b.id - +a.id)

  const trialBillingCycleDays = FIXED_TERM_TRIAL_CYCLES.includes(trialDaysCount)
    ? trialDaysCount
    : roundToNearestTenth(trialDaysCount)

  return {
    subscriptionId,
    trialStartDate: getDateFromTimeStamp(trialStartDate),
    trialEndDate: getDateFromTimeStamp(trialEndDate),
    startDate: getDateFromTimeStamp(startDate),
    endDate: getDateFromTimeStamp(endDate),
    trialDaysCount: trialBillingCycleDays,
    intervalCount,
    interval,
    status,
    price: amount / CENTS_IN_DOLLAR,
    trialPrice: trialAmount / CENTS_IN_DOLLAR,
    currency: currency.toUpperCase(),
  }
}
