import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'

import { selectError } from 'root-redux/selects/common'

import { selectIsModalShown } from 'modules/contactForm/redux'

import { StyledModal as S } from './Modal.styles'

type TProps = {
  userEmail?: string
  onClose: () => void
}

export const Modal: React.FC<TProps> = ({ userEmail, onClose }) => {
  const { t } = useTranslation()
  const isShown = useSelector(selectIsModalShown)
  const error = useSelector(selectError)

  return (
    <S.Wrapper isShown={isShown}>
      <S.Content>
        {error ? (
          <S.Title>
            <Trans i18nKey={error} />
          </S.Title>
        ) : (
          <>
            <S.Title>We’ve received your request!</S.Title>
            <S.Text>
              You’ll get a reply to your email <S.Email>{userEmail}</S.Email>{' '}
              within 24 hours.
            </S.Text>
          </>
        )}
        <Button type="button" onClick={onClose}>
          {t('login.gotIt')}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
