import { IRequestOptions, IResponseResult } from 'models/api.model'

import { ApiService } from 'services/api.service'

import { RequestMethod } from 'root-constants'

export class CaptchaApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  verifyCaptchaToken(
    captchaToken: string | null,
  ): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: {
        secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY || '',
        response: captchaToken || '',
      },
    }

    return this.api.makeRequest<never>('api/siteverify', options)
  }
}
