import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export type TErrorProps = {
  minHeight: number
  isVisible: boolean
}

export const StyledInputWithFloatPlaceholder = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    min-width: 320px;

    &[data-valid='false'] > input {
      border: 1px solid ${Color.ERROR};
    }
  `,
  ValidationText: styled.p<TErrorProps>`
    display: block;
    margin: 4px 0;
    padding-left: 16px;
    max-width: 320px;
    color: ${Color.ERROR};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    min-height: ${({ minHeight }) => `${minHeight}px`};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  `,
  Input: styled.input`
    display: block;
    width: 100%;
    height: 64px;
    padding: 30px 52px 12px 16px;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: #4c4c4c;
    border-radius: 16px;
    border: 1px solid #a9a9a9;
    background: ${Color.WHITE};
    outline: none;
    caret-color: #4c4c4c;
    transition: all 0.2s ease-out;

    &:valid,
    &:focus {
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #000;

      & + span {
        top: 9px;
        color: ${Color.GRAY};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 20px;
    left: 16px;
    color: ${Color.GRAY};
    font-size: 16px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  Icon: styled(SvgImage)`
    position: absolute;
    top: 32px;
    transform: translateY(-50%);
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  `,
  SvgIcon: styled(SvgImage)`
    position: absolute;
    top: 32px;
    right: 16px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  `,
}
