import {
  IContactForm,
  IUploadedFile,
  IZendeskRequest,
} from 'models/zendesk.model'

import { SUPPORT_EMAILS, ZENDESK_APP_NAMES } from 'root-constants'

export const createZendeskRequest = ({
  appName,
  formData: { topic, request, name, email },
  uploadedFiles,
}: {
  appName: string
  formData: IContactForm
  uploadedFiles: IUploadedFile[]
}): IZendeskRequest => ({
  request: {
    subject: `${ZENDESK_APP_NAMES[appName]}: ${topic}`,
    recipient: SUPPORT_EMAILS[appName],
    comment: {
      body: request,
      uploads: uploadedFiles.map(({ token = '' }) => token),
    },
    requester: {
      name,
      email,
    },
  },
})
