import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getAuth, getRedirectResult } from 'firebase/auth'

import { selectSource } from 'root-redux/selects/common'
import { selectUUID, selectUserContactEmail } from 'root-redux/selects/user'

import { getLoginMethodFromLocalStorage } from 'helpers/getLoginMethodFromLocalStorage'

import { eventLogger } from 'services/eventLogger.service'

export const useGetRedirectResult = (
  callback: (token: string) => void,
  callbackIsDataLoading: (isLoading: boolean) => void,
): void => {
  const userId = useSelector(selectUUID)
  const source = useSelector(selectSource)
  const email = useSelector(selectUserContactEmail)

  useEffect(() => {
    callbackIsDataLoading(true)

    const auth = getAuth()
    getRedirectResult(auth)
      .then((result) => {
        if (result?.user) {
          return result.user.getIdToken()
        }

        return null
      })
      .then((token) => {
        token && callback(token)
      })
      .catch((error) =>
        eventLogger.logCancelSubscriptionFailed({
          userId,
          error: error.code,
          source,
          email,
          method: getLoginMethodFromLocalStorage(),
        }),
      )
      .finally(() => callbackIsDataLoading(false))
  }, [callback, callbackIsDataLoading, email, source, userId])
}
