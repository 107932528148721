/* eslint-disable max-lines */
import { answerTheme, answerWithCheckboxTheme, buttonTheme } from 'storybook-ui'

import { OnboardingGoalPageId } from 'page-constants'

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export const ALPHABETIC_SYMBOLS_REGEXP = /[^\p{L}\p{Z}]+/gu
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const MIN_PASSWORD_LENGTH = 6
export const NO_DATA_ERROR = 'No data'
export const SUBSCRIPTION_MISSING_ERROR = 'subscription_missing'
export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const USER_ID_KEY = 'userId'

export const enum AppName {
  LUVLY = 'facetory',
  DANCEBIT = 'dancebit',
  NUTRIMATE = 'myfast',
  FAMIO = 'famio',
  UPLUV = 'upluv',
  WOOFZ = 'woofz',
  CARDIMATE = 'cardimate',
  FITME = 'fitme',
  MEOWZ = 'meowz',
}

export const APP_NAMES = {
  luvly: AppName.LUVLY,
  dancebit: AppName.DANCEBIT,
  'dance-bit': AppName.DANCEBIT,
  fasting: AppName.NUTRIMATE,
  nutrimate: AppName.NUTRIMATE,
  famio: AppName.FAMIO,
  myfamio: AppName.FAMIO,
  upluv: AppName.UPLUV,
  woofz: AppName.WOOFZ,
  meowz: AppName.MEOWZ,
  cardimate: AppName.CARDIMATE,
  fitme: AppName.FITME,
  localhost: AppName.FITME,
}

export const NOVE8_APPS: string[] = [AppName.MEOWZ, AppName.WOOFZ]

export const RETENTION_FLOW_APPS = [
  AppName.LUVLY,
  AppName.DANCEBIT,
  AppName.NUTRIMATE,
  AppName.FITME,
  AppName.WOOFZ,
]

export const FIREBASE_ERRORS = {
  WRONG_PASSWORD: 'auth/wrong-password',
  USER_NOT_FOUND: 'auth/user-not-found',
  USER_DISABLED: 'auth/user-disabled',
  INVALID_EMAIL: 'auth/invalid-email',
}

export const enum Color {
  LUVLY = '#628f7b',
  LUVLY_PRIMARY = '#ff8276',
  DANCEBIT = '#03aaf5',
  NUTRIMATE = '#a9cd50',
  FAMIO = '#6336ff',
  UPLUV = '#4946d2',
  WOOFZ = '#35b43f',
  CARDIMATE = '#2573ff',
  FITME = '#8378ef',
  SUCCESS = '#93c451',
  ERROR = '#f83b00',
  WHITE = '#fff',
  DARK = '#262634',
  DARK_GRAY = '#2c3131',
  GRAY = '#626262',
  GRAY_30 = '#e9e9e9',
  GRAY_20 = '#f2f3f5',
  LINK_COLOR = '#0091ff',
  BLACK = '#000',
  BLUE_DARK = '#17202a',
}

export const APP_COLORS = {
  [AppName.LUVLY]: Color.LUVLY,
  [AppName.DANCEBIT]: Color.DANCEBIT,
  [AppName.NUTRIMATE]: Color.NUTRIMATE,
  [AppName.FAMIO]: Color.FAMIO,
  [AppName.UPLUV]: Color.UPLUV,
  [AppName.WOOFZ]: Color.WOOFZ,
  [AppName.MEOWZ]: Color.WOOFZ,
  [AppName.CARDIMATE]: Color.CARDIMATE,
  [AppName.FITME]: Color.FITME,
}

export const APP_BG_COLORS = {
  [AppName.LUVLY]: '#f6f4ef',
  [AppName.DANCEBIT]: '#e6f7fe',
  [AppName.NUTRIMATE]: '#f6faee',
  [AppName.FAMIO]: '#efebff',
  [AppName.UPLUV]: '#ededfb',
  [AppName.WOOFZ]: '#fffaf7',
  [AppName.MEOWZ]: '#fffaf7',
  [AppName.CARDIMATE]: '#f0f2fa',
  [AppName.FITME]: '#f5f4ff',
}

export type TRetentionFlowApps =
  | AppName.LUVLY
  | AppName.DANCEBIT
  | AppName.NUTRIMATE
  | AppName.FITME
  | AppName.WOOFZ

export const RETENTION_APP_COLORS_MAP: Record<TRetentionFlowApps, Color> = {
  [AppName.LUVLY]: Color.LUVLY_PRIMARY,
  [AppName.DANCEBIT]: Color.DANCEBIT,
  [AppName.NUTRIMATE]: Color.NUTRIMATE,
  [AppName.FITME]: Color.FITME,
  [AppName.WOOFZ]: Color.WOOFZ,
}

export const READABLE_APP_NAMES = {
  [AppName.LUVLY]: 'Luvly',
  [AppName.DANCEBIT]: 'Dancebit',
  [AppName.NUTRIMATE]: 'NutriMate',
  [AppName.FAMIO]: 'Famio',
  [AppName.UPLUV]: 'UpLuv',
  [AppName.WOOFZ]: 'Woofz',
  [AppName.MEOWZ]: 'Nove8',
  [AppName.CARDIMATE]: 'Cardimate',
  [AppName.FITME]: 'Fitme',
}

export const APP_OPTION_THEMES: Record<TRetentionFlowApps, answerTheme> = {
  [AppName.LUVLY]: answerTheme.ACCOUNT_LUVLY,
  [AppName.DANCEBIT]: answerTheme.ACCOUNT_DANCEBIT,
  [AppName.NUTRIMATE]: answerTheme.ACCOUNT_NUTRIMATE,
  [AppName.FITME]: answerTheme.ACCOUNT_FITME,
  [AppName.WOOFZ]: answerTheme.ACCOUNT_NUTRIMATE,
}

export const APP_OPTION_WITH_CHECKBOX_THEMES: Record<
  TRetentionFlowApps,
  answerWithCheckboxTheme
> = {
  [AppName.LUVLY]: answerWithCheckboxTheme.ACCOUNT_LUVLY,
  [AppName.DANCEBIT]: answerWithCheckboxTheme.ACCOUNT_DANCEBIT,
  [AppName.NUTRIMATE]: answerWithCheckboxTheme.ACCOUNT_NUTRIMATE,
  [AppName.FITME]: answerWithCheckboxTheme.ACCOUNT_FITME,
  [AppName.WOOFZ]: answerWithCheckboxTheme.ACCOUNT_NUTRIMATE,
}

export const APP_BUTTON_THEMES: Record<TRetentionFlowApps, buttonTheme> = {
  [AppName.LUVLY]: buttonTheme.LUVLY,
  [AppName.DANCEBIT]: buttonTheme.DANCEBIT,
  [AppName.NUTRIMATE]: buttonTheme.NUTRIMATE_PRIMARY,
  [AppName.FITME]: buttonTheme.FITMI,
  [AppName.WOOFZ]: buttonTheme.NUTRIMATE_PRIMARY,
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const DAYS_IN_WEEK = 7
export const DAYS_IN_MONTH = 30
export const MONTHS_IN_YEAR = 12

export const enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  CANCELLED = 'cancelled',
  NOT_ACTIVE = 'not_active',
  DEACTIVATED = 'deactivated',
  PAST_DUE = 'past_due',
}

export const enum SubscriptionUIStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  CANCELED = 'canceled',
  PAUSED = 'paused',
}

export const enum ExtraSubscriptionUIStatus {
  NOT_ACTIVE = 'not_active',
}

export type TExtendedSubscriptionUIStatus =
  | SubscriptionUIStatus
  | ExtraSubscriptionUIStatus

export const SUPPORT_EMAILS = {
  [AppName.LUVLY]: 'support@luvly.care',
  [AppName.DANCEBIT]: 'support@dance-bit.com',
  [AppName.NUTRIMATE]: 'support@nutrimate.fit',
  [AppName.FAMIO]: 'support@myfamio.com',
  [AppName.UPLUV]: 'upluv-support@gismart.com',
  [AppName.WOOFZ]: 'support@woofz.com',
  [AppName.MEOWZ]: 'support@meowz.app',
  [AppName.CARDIMATE]: 'support@cardimate.com',
  [AppName.FITME]: 'support@fitme.health',
}

export const APP_TERMS_OF_USE_LINKS = {
  [AppName.LUVLY]: 'https://luvly.care/terms-of-use',
  [AppName.DANCEBIT]: 'https://www.dancebit.dance/terms-of-use',
  [AppName.FAMIO]: 'https://myfamio.com/terms-of-use',
  [AppName.UPLUV]: 'https://harmonybit.com/terms-of-use',
  [AppName.NUTRIMATE]: 'https://nutrimate.fit/terms-of-use',
  [AppName.WOOFZ]: 'https://www.woofz.com/terms-of-use/',
  [AppName.MEOWZ]: 'https://meowz.app/terms_of_use',
  [AppName.CARDIMATE]: 'https://cardimate.com/terms-of-use',
  [AppName.FITME]: 'https://quiz.fitme.health/terms-of-use',
}

export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'custom_token'

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const OPEN_APP_LINKS = {
  [AppName.LUVLY]: {
    [LoginMethod.EMAIL]:
      'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Password&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dpassword',
    [LoginMethod.GOOGLE]:
      'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Google&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dgoogle.com',
    [LoginMethod.FACEBOOK]:
      'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Facebook&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dfacebook.com',
    [LoginMethod.APPLE]:
      'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Apple&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dapple.com',
  },
  [AppName.FAMIO]: {
    [LoginMethod.EMAIL]:
      'https://famio.onelink.me/b9Ag?af_xp=custom&pid=web2web_payment_success&deep_link_value=custom_token&deep_link_sub1=password',
    [LoginMethod.APPLE]:
      'https://famio.onelink.me/b9Ag?af_xp=custom&pid=web2web_payment_success&deep_link_value=custom_token&deep_link_sub1=apple.com',
  },
  [AppName.UPLUV]: {
    [LoginMethod.EMAIL]:
      'https://upluv.onelink.me/5Qwq/mv3abvne?deep_link_value=custom_token',
  },
  [AppName.WOOFZ]: {
    [LoginMethod.EMAIL]:
      'https://qa-test.onelink.me/8Y0L/1b9c1ed6?deep_link_value=custom_token',
  },
  [AppName.MEOWZ]: {
    [LoginMethod.EMAIL]:
      'https://qa-test.onelink.me/8Y0L/1b9c1ed6?deep_link_value=custom_token',
  },
  [AppName.CARDIMATE]: {
    [LoginMethod.EMAIL]:
      'https://cardimate.onelink.me/AxaV/dzf0oh2m?deep_link_value=custom_token&deep_link_sub1=password',
    [LoginMethod.APPLE]:
      'https://cardimate.onelink.me/AxaV/dzf0oh2m?deep_link_value=custom_token&deep_link_sub1=apple.com',
  },
  [AppName.FITME]: {
    [LoginMethod.EMAIL]:
      'https://fitmeworkouts.onelink.me/69GB?af_xp=custom&deep_link_value=custom_token&pid=web2web_payment_success&c=FitMe_DL_Login&deep_link_sub1=password',
    [LoginMethod.APPLE]:
      'https://fitmeworkouts.onelink.me/69GB?af_xp=custom&deep_link_value=custom_token&pid=web2web_payment_success&c=FitMe_DL_Login&deep_link_sub1=apple.com',
  },
  [AppName.DANCEBIT]: {
    [LoginMethod.EMAIL]:
      'https://dancebit.onelink.me/cJoT?pid=web2web_payment_success&c=Dancebit_DL_Password&deep_link_value=custom_token&af_dp=harmonybitdancebit%3A%2F%2F%3Flogin%3D&deep_link_sub1=password',
  },
  [AppName.NUTRIMATE]: {
    [LoginMethod.EMAIL]:
      'https://myfast.onelink.me/i1q0?pid=web2web_payment_success&c=myfast_DL_Email&deep_link_value=custom_token&af_dp=myfast%3A%2F%2F%3Flogin%3D&deep_link_sub1=password',
  },
}

export const APP_PRIVACY_POLICY_LINKS = {
  [AppName.LUVLY]: 'https://luvly.care/privacy-policy',
  [AppName.DANCEBIT]: 'https://www.dancebit.dance/privacy-policy',
  [AppName.FAMIO]: 'https://myfamio.com/privacy-policy',
  [AppName.UPLUV]: 'https://upluv.one/privacy-policy',
  [AppName.NUTRIMATE]: 'https://nutrimate.fit/privacy-policy',
  [AppName.WOOFZ]: 'https://www.woofz.com/privacy-policy/',
  [AppName.MEOWZ]: 'https://meowz.app/privacy_policy',
  [AppName.CARDIMATE]: 'https://cardimate.com/privacy-policy',
  [AppName.FITME]: 'https://quiz.fitme.health/privacy-policy',
}

export const CONTACT_FORM_LINK = '/contact-form'

export const SUPPORT_FORM_LINKS = {
  [AppName.DANCEBIT]: 'https://support.dance-bit.com/hc/en-us',
}

export const ZENDESK_API_URLS = {
  general: 'https://gismart.zendesk.com/api',
  nove8: 'https://nove8help.zendesk.com/api',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
  AMPLITUDE = 'amplitude',
}

export const enum CurrentEnvironment {
  DEV = 'dev',
  PROD = 'prod',
  STAGE = 'stage',
}

export const enum Source {
  APP = 'mobile_app',
  SUPPORT = 'support',
}

export const enum SubscriptionInterval {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const enum PaymentSystem {
  PRIMER = 'primer',
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
}

export const enum TrialBillingCycle {
  ONE_DAY = 1,
  TWO_DAYS = 2,
  THREE_DAYS = 3,
  FOUR_DAYS = 4,
  SIX_DAYS = 6,
  SEVEN_DAYS = 7,
  FOUR_WEEKS = 28,
  ONE_MONTH = 30,
  TWELVE_WEEKS = 84,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export const FIXED_TERM_TRIAL_CYCLES = [
  TrialBillingCycle.ONE_DAY,
  TrialBillingCycle.TWO_DAYS,
  TrialBillingCycle.THREE_DAYS,
  TrialBillingCycle.FOUR_DAYS,
  TrialBillingCycle.SIX_DAYS,
  TrialBillingCycle.SEVEN_DAYS,
  TrialBillingCycle.FOUR_WEEKS,
  TrialBillingCycle.TWELVE_WEEKS,
]
export const TRIAL_DAILY_PERIOD = 'day'
export const TRIAL_MONTHLY_PERIOD = 'months'
export const TRIAL_BILLING_CYCLE_KEYS = {
  [TrialBillingCycle.ONE_MONTH]: 'month',
  [TrialBillingCycle.SEVEN_DAYS]: 'week',
  [TrialBillingCycle.FOUR_WEEKS]: 'weeks',
  [TrialBillingCycle.TWELVE_WEEKS]: 'weeks',
}

export const enum EVENT_SOURCE {
  ACCOUNT_PROFILE = 'account_details_page',
  PAYWALL = 'paywall',
  RESET_PASSWORD = 'reset_password_screen',
  UNSUBSCRIBE_LOGIN = 'unsub_login_page',
  DISCOUNT_OFFER = 'unsub_discount_offer_screen',
}

export const LOGIN_METHOD_KEY = 'method'

export const enum Language {
  EN = 'en',
}

export const enum SubscriptionType {
  SUBSCRIPTION = 'subscription',
  SECONDARY = 'secondary',
  UPSELL = 'upsell',
}

export const enum SubscriptionListType {
  UPGRADE = 'upgrade',
}

export const enum SubscriptionTagsType {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  UNSUBSCRIBE = 'unsubscribe',
}

export const enum UnsubscribeReason {
  DID_NOT_GET_WHAT_EXPECTED = 'did_not_get_what_expected',
  DID_NOT_GET_WEIGHT_GOAL = 'did_not_get_weight_goal',
  TIMING_IS_NOT_RIGHT = 'timing_is_not_right',
  TOO_EXPENSIVE = 'too_expensive',
  DIFFICULTIES = 'difficulties',
  NO_RESULTS = 'no_results',
  NO_UPDATES = 'no_updates',
  DO_NOT_LIKE_PROGRAM = 'do_not_like_program',
  DO_NOT_UNDERSTAND_PROGRAM = 'do_not_understand_program',
  HEALTH_PROBLEMS = 'health_problems',
  TECH_ISSUES = 'tech_issues',
  OTHER = 'other',
}

export const VAT_INCLUDED_COUNTRY: Record<TRetentionFlowApps, string[]> = {
  [AppName.LUVLY]: ['US', 'CA', 'AU'],
  [AppName.DANCEBIT]: [
    'CA',
    'NZ',
    'AU',
    'US',
    'GB',
    'AT',
    'BE',
    'CY',
    'DE',
    'EE',
    'FI',
    'FR',
    'GR',
    'HR',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PT',
    'SK',
    'SI',
    'ES',
    'MX',
    'BR',
    'JP',
  ],
  [AppName.NUTRIMATE]: ['US', 'MX', 'BR', 'ES'],
  [AppName.FITME]: [],
  [AppName.WOOFZ]: [],
}

export const APPS_USER_GOAL_MAP: Record<
  TRetentionFlowApps,
  OnboardingGoalPageId
> = {
  [AppName.LUVLY]: OnboardingGoalPageId.INTRO,
  [AppName.DANCEBIT]: OnboardingGoalPageId.MAIN_GOAL,
  [AppName.NUTRIMATE]: OnboardingGoalPageId.PRIMARY_GOAL,
  [AppName.FITME]: OnboardingGoalPageId.FITME_MAIN_GOAL,
  [AppName.WOOFZ]: OnboardingGoalPageId.WOOFZ_MAIN_GOAL,
}

export const enum LuvlyUserGoal {
  WRINKLE_FREE_SKIN = 'get_rid_of_wrinkles',
  REDUCE_FACE_FAT = 'reduce_face_fat',
  BOTH_OPTIONS = 'both_options',
  TIGHTEN_SKIN = 'tighten_skin',
}

export const enum DancebitUserGoal {
  STAY_FIT = 'stayFit',
  LOSE_WEIGHT = 'loseWeight',
}

export const enum NutrimateUserGoal {
  LOSE_WEIGHT = 'lose_weight',
  GET_FIT = 'get_fit',
  BUILD_HEALTHY_HABITS = 'build_healthy_habits',
  GET_HEALTHY_HABITS = 'get_healthy',
}

export const enum FitmeUserGoal {
  WEIGHT = 'lose_weight',
  MUSCLES = 'tone_muscles',
  HEALTH = 'improve_mental_health',
  FIT = 'keep_fit',
}

export const enum WoofzUserGoal {
  BEHAVIOUR_PROBLEMS = 'behaviour_problems',
  NEW_TRICKS = 'new_tricks',
  HOME_ADAPTATION = 'home_adaptation',
  PREVENT_PROBLEMS = 'prevent_problems',
}

export type TUserGoal =
  | LuvlyUserGoal
  | DancebitUserGoal
  | NutrimateUserGoal
  | FitmeUserGoal
  | WoofzUserGoal

export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  NZD = 'nzd',
  MXN = 'mxn',
  BRL = 'brl',
  JP = 'jpy',
  PLN = 'pln',
}

export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.GBP]: '£',
  [Currency.NZD]: 'NZ$',
  [Currency.MXN]: 'MXN',
  [Currency.BRL]: 'R$',
  [Currency.JP]: '¥',
  [Currency.PLN]: 'zł',
}

export const UNSUBSCRIBE_COHORT_NAMES_MAP: Record<TRetentionFlowApps, string> =
  {
    [AppName.LUVLY]: 'luvly_unsubscribe',
    [AppName.DANCEBIT]: 'db_unsubscribe',
    [AppName.NUTRIMATE]: 'mf_unsubscribe',
    [AppName.FITME]: 'fitme_unsubscribe',
    [AppName.WOOFZ]: 'woofz_unsubscribe',
  }

export const enum ExtraSubscription {
  WEIGHT_LOSS_BUNDLE = 'WeightLossBundle', // dancebit
  WEIGHT_LOSS_BUNDLE_FREE = 'WeightLossBundleFree', // dancebit
  EXTRA_COURSES = 'Extra courses', // dancebit
  SEVEN_DAY = 'sevenDay', // dancebit
  FLAT_TUMMY = 'FlatTummy', // dancebit
  ULTIMATE_TONING_PILATES = 'UltimateToningPilates', // dancebit
  SUPERCHARGE_YOURSELF = 'SuperchargeYourself', // dancebit
  FEMININITY_PROGRAM = 'FemininityProgram', // dancebit
  LUVLY_PROGRAM = 'LuvlyProgram', // dancebit
  INTENSE_CALORIE_BURNING_DANCES = 'IntenseCalorieBurningDances', // dancebit
  CHIN_FACE_LIFTING = 'doubleChinAndFaceLifting', // luvly
  CHIN_SHAPER = 'doubleChin', // luvly
  FACE_LIFTING = 'faceLifting', // luvly
  HAIR_GUIDE = 'hairCareUpsell', // luvly
  FITNESS_SELFCARE = 'pdf_FitnessAndSelfcare', // nutrimate
  FITNESS = 'pdf_fitness', // nutrimate
  SELFCARE = 'pdf_selfcare', // nutrimate
  SIMPLE_CHANGES = 'pdf_SimpleChanges', // nutrimate
  SUGAR_FREE_DESSERTS = 'pdf_SugarFreeDesserts', // nutrimate
  WEIGHT_LOSS_GUIDES = 'pdf_WeightLossGuides', // nutrimate
  DIET = 'hormonal_receipts_pdf', // fitme
  COOKBOOK = 'desserts_recipes_pdf', // fitme
  SENIOR_FITNESS = 'seniorFitness', // fitme
  BURN_BELLY_FAT = 'burnBellyFat', // fitme
  BUBBLE_BUTT = 'bubbleButt', // fitme
  BUTT_AND_BELLY = 'bubbleButtAndBurnBellyFat', // fitme
  FITNESS_AND_BELLY = 'seniorFitnessAndBurnBellyFat', // fitme
}

export const enum SecondarySubscription {
  LUVLY_COACHING = 'luvlyCoaching', // luvly
}
