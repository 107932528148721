import styled from 'styled-components'

import { Color } from 'root-constants'

export const FooterContainerStyles = {
  Container: styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    width: 100%;
    padding: 16px 24px;
    border-top: 1px solid ${Color.GRAY_20};
    background-color: ${Color.WHITE};
  `,
}
