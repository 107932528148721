import { RefObject, useEffect } from 'react'

export const useClickOutside = ({
  ref,
  callback,
}: {
  ref: RefObject<HTMLElement>
  callback: () => void
}) => {
  useEffect(() => {
    const handleClick = ({ target }) => {
      if (ref.current && !ref.current.contains(target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [callback, ref])
}
