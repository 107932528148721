import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledSubscriptionDetails = {
  Wrapper: styled.div<{ hasOpenInAppLink: boolean }>`
    padding-top: ${({ hasOpenInAppLink }) => (hasOpenInAppLink ? '78px' : '0')};
    padding-bottom: 60px;
  `,
  CardTitle: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.BLACK};
    margin-bottom: 16px;
  `,
  InfoRow: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 17px;
    color: ${Color.BLACK};
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
    word-break: break-all;

    & > span:first-of-type {
      margin-bottom: 4px;
    }
  `,
  SubscriptionRow: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    color: ${Color.BLACK};
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;

    & > span:first-of-type {
      max-width: 148px;
    }
  `,
  InfoValue: styled.span`
    font-weight: 600;
  `,
  ResetPassword: styled.a`
    color: ${Color.BLACK};
    display: flex;
    justify-content: end;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline;
    cursor: pointer;
  `,
  Container: styled(Container)`
    padding-bottom: 32px;
  `,
  UnsubscribeInfo: styled.p`
    padding-top: 16px;
    border-top: 1px solid #e9e9e9;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
  Separator: styled.div`
    width: 100%;
    height: 1px;
    background: #e9e9e9;
    margin-bottom: 16px;
  `,
}
