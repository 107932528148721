import { commonApi, userApi } from 'api'
import { getAuth, signOut } from 'firebase/auth'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { selectAppName, selectSource } from 'root-redux/selects/common'
import {
  selectAuthToken,
  selectUUID,
  selectUserContactEmail,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'

import { getActiveUserSubscriptionFromRawUserSubscriptions } from 'helpers/getActiveUserSubscriptionFromRawUserSubscriptions'
import { getLoginMethodFromLocalStorage } from 'helpers/getLoginMethodFromLocalStorage'
import { getUserPaymentMethodFromRaw } from 'helpers/getUserPaymentMethodFromRaw'

import { setIsModalShownAction } from 'modules/contactForm/redux'

import { IUserSubscriptionsInfo } from 'models/commonApi.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { NO_DATA_ERROR, SubscriptionStatus } from 'root-constants'

const MODULE_NAME = 'USER'

export const SET_AUTH_TOKEN = `${MODULE_NAME}/SET_AUTH_TOKEN`
export const SET_USER_SUBSCRIPTION_INFO = `${MODULE_NAME}/SET_USER_SUBSCRIPTION_INFO`
export const SET_USER_CONTACT_EMAIL = `${MODULE_NAME}/SET_USER_CONTACT_EMAIL`
export const SET_USER_NAME = `${MODULE_NAME}/SET_USER_NAME`
export const UNSUBSCRIBE = `${MODULE_NAME}/UNSUBSCRIBE`
export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const GET_AUTOLOGIN_TOKEN = `${MODULE_NAME}/GET_AUTOLOGIN_TOKEN`
export const SET_AUTOLOGIN_TOKEN = `${MODULE_NAME}/SET_AUTOLOGIN_TOKEN`

export function setAuthTokenAction(authToken: string): IAction<string> {
  return {
    type: SET_AUTH_TOKEN,
    payload: authToken,
  }
}

export function setUserContactEmailAction(
  customerContactEmail: string,
): IAction<string> {
  return {
    type: SET_USER_CONTACT_EMAIL,
    payload: customerContactEmail,
  }
}

export function setUserNameAction(userName: string): IAction<string> {
  return {
    type: SET_USER_NAME,
    payload: userName,
  }
}

export function setUserSubscriptionsInfoAction(
  subscriptionInfo: IUserSubscriptionsInfo | null,
): IAction<IUserSubscriptionsInfo | null> {
  return {
    type: SET_USER_SUBSCRIPTION_INFO,
    payload: subscriptionInfo,
  }
}

export function setAutologinTokenAction(payload: string): IAction<string> {
  return {
    type: SET_AUTOLOGIN_TOKEN,
    payload,
  }
}

export function getUserSubscriptionsInfoAction(
  errorCallback: () => void,
): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const appName = selectAppName(state)
    const authToken = selectAuthToken(state)
    const source = selectSource(state)
    const email = selectUserContactEmail(state)

    if (!authToken) {
      goTo('/login')
      return
    }

    const response = await commonApi.getUserSubscriptionsInfo(
      authToken,
      appName,
    )

    if (response.success && response.data) {
      const subscriptionInfo =
        getActiveUserSubscriptionFromRawUserSubscriptions(
          response.data.subscriptions,
        )

      dispatch(setUserSubscriptionsInfoAction(subscriptionInfo))
      return
    }

    if (response.success && !response.data) {
      dispatch(setErrorAction(i18n.t('login.error.userNotFound')))
      goTo('/login')
      errorCallback()

      eventLogger.logLoginFailed({
        error: i18n.t('login.error.userNotFound'),
        source,
        email,
        method: getLoginMethodFromLocalStorage(),
      })
      return
    }

    if (!response.success && response.status === 400) {
      dispatch(setErrorAction(''))
      goTo('/login')
      errorCallback()
      return
    }

    if (!response.success && response.status === 404) {
      dispatch(setErrorAction(i18n.t('login.error.customerNotFound')))
      goTo('/login')
      errorCallback()
      return
    }

    dispatch(
      setErrorAction(response.data?.error || i18n.t('login.error.common')),
    )
    goTo('/login')
    errorCallback()
  }
}

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function getCustomerInfoAction({
  hasEvent,
}: {
  hasEvent: boolean
}): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const appName = selectAppName(state)
    const authToken = selectAuthToken(state)
    const source = selectSource(state)

    const response = await commonApi.getCustomerInfo(authToken, appName)

    if (response.success && response.data) {
      const { customer } = response.data
      dispatch(
        setUserContactEmailAction(
          customer.contact_email || customer.login_email,
        ),
      )
      dispatch(setUserNameAction(customer.onboarding?.name || ''))
      dispatch(setUUIDAction(customer.public_id))

      hasEvent &&
        eventLogger.logLoginCompleted({
          userId: customer.public_id,
          source,
          email: customer.contact_email,
          method: getLoginMethodFromLocalStorage(),
        })
    }
  }
}

export function unsubscribeUserByTokenAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const authToken = selectAuthToken(state)
    const appName = selectAppName(state)
    const userSubscriptionInfo = selectUserSubscriptionInfo(state)
    const source = selectSource(state)
    const email = selectUserContactEmail(state)
    const userId = selectUUID(state)

    const loginMethod = getLoginMethodFromLocalStorage()

    let paymentMethod: string

    if (!authToken) {
      goTo('login')
      return
    }

    dispatch(startFetching(UNSUBSCRIBE))

    try {
      const userStatusResponse = await userApi.getUserStatus(userId, appName)
      paymentMethod = getUserPaymentMethodFromRaw(userStatusResponse.data.state)
    } catch {
      paymentMethod = NO_DATA_ERROR
    }

    try {
      const response = await commonApi.unsubscribeByToken({
        subscriptionId: userSubscriptionInfo?.subscriptionId as string,
        token: authToken,
        appName,
      })

      if (response.success) {
        eventLogger.logSubscriptionCancelled({
          userId,
          productId: userSubscriptionInfo?.subscriptionId as string,
          price: `${userSubscriptionInfo?.price}${userSubscriptionInfo?.currency}`,
          paymentMethod,
          source,
          email,
          method: loginMethod,
        })

        userSubscriptionInfo &&
          dispatch(
            setUserSubscriptionsInfoAction({
              ...userSubscriptionInfo,
              status: SubscriptionStatus.CANCELLED,
            }),
          )
        dispatch(stopFetching(UNSUBSCRIBE))
        return
      }

      dispatch(setIsModalShownAction(true))
      dispatch(setErrorAction(i18n.t('commonError')))

      eventLogger.logCancelSubscriptionFailed({
        userId,
        error: response.data.error || i18n.t('login.error.common'),
        source,
        email,
        method: loginMethod,
      })

      dispatch(getUserSubscriptionsInfoAction(() => signOut(getAuth())))

      dispatch(stopFetching(UNSUBSCRIBE))
    } catch (error: any) {
      eventLogger.logCancelSubscriptionFailed({
        userId,
        error,
        source,
        email,
        method: loginMethod,
      })
      dispatch(getUserSubscriptionsInfoAction(() => signOut(getAuth())))
      dispatch(stopFetching(UNSUBSCRIBE))
    }
  }
}

export function getAutologinTokenAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<string>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const appName = selectAppName(state)

    dispatch(startFetching(GET_AUTOLOGIN_TOKEN))

    const autologinTokenResponse = await userApi.getAutologinToken(
      uuid,
      appName,
    )

    if (!autologinTokenResponse.success || !autologinTokenResponse.data) {
      dispatch(setErrorAction(i18n.t('commonError')))
      dispatch(stopFetching(GET_AUTOLOGIN_TOKEN))
    }

    dispatch(setAutologinTokenAction(autologinTokenResponse.data.custom_token))

    dispatch(stopFetching(GET_AUTOLOGIN_TOKEN))
  }
}
