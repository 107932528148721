import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Header } from 'components/Header'
import { Spinner } from 'components/Spinner'

import {
  getCustomerInfoAction,
  setAuthTokenAction,
} from 'root-redux/actions/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'

import { EmailLogin } from 'modules/unsubscribeLogin/components/EmailLogin'

import { goTo } from 'browser-history'

import { StyledUnsubscribeLogin as S } from './UnsubscribeLogin.styles'

export const UnsubscribeLogin: React.FC = () => {
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()

  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(setAuthTokenAction(token))
      dispatch(getCustomerInfoAction({ hasEvent: true }))

      goTo(
        search
          ? `subscription-details${search}&token=${token}`
          : `subscription-details?token=${token}`,
      )
    },
    [dispatch, search],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <S.Wrapper>
      {isFirebaseDataLoading && <Spinner />}
      <Header />
      <EmailLogin />
    </S.Wrapper>
  )
}
