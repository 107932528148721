import { AnyAction } from 'redux'

import {
  ADD_FILE,
  REMOVE_ALL_FILES,
  REMOVE_FILE,
  SET_IS_CAPTCHA_VALID,
  SET_IS_MODAL_SHOWN,
} from 'modules/contactForm/redux/actions'

import { IUploadedFile } from 'models/zendesk.model'

export interface IContactFormState {
  uploadedFiles: IUploadedFile[]
  isModalShown: boolean
  isCaptchaValid: boolean
}

const initialState: IContactFormState = {
  uploadedFiles: [],
  isModalShown: false,
  isCaptchaValid: false,
}

export function contactFormReducer(
  state = initialState,
  { type, payload }: AnyAction,
): IContactFormState {
  switch (type) {
    case ADD_FILE: {
      return {
        ...state,
        uploadedFiles: [...state.uploadedFiles, payload],
      }
    }
    case REMOVE_FILE: {
      return {
        ...state,
        uploadedFiles: state.uploadedFiles.filter(
          ({ fileId }) => fileId !== payload,
        ),
      }
    }
    case REMOVE_ALL_FILES: {
      return {
        ...state,
        uploadedFiles: [],
      }
    }
    case SET_IS_MODAL_SHOWN: {
      return {
        ...state,
        isModalShown: payload,
      }
    }
    case SET_IS_CAPTCHA_VALID: {
      return {
        ...state,
        isCaptchaValid: payload,
      }
    }
    default:
      return state
  }
}
