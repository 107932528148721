import amplitude from 'amplitude-js'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { IEventLogger } from 'models/events.model'

import { EventLoggerInstanceName } from 'root-constants'

interface IAmplitude {
  getInstance: (amplitudeInstanceName?: string) => typeof amplitude
  init: (
    apiKey: string,
    userId: string | null,
    options?: Record<string, unknown>,
  ) => void
  setUserId: (userId: string) => void
  setUserProperties: (properties: Record<string, string>) => void
  logEvent: (event: string, eventProperty?: Record<string, unknown>) => void
}

export class Amplitude implements IEventLogger {
  public readonly name: EventLoggerInstanceName
  private readonly amplitude: IAmplitude
  private cohort = ''
  private userProperties: Record<string, string> = {}

  constructor({
    instanceName,
    apiKey,
    userId,
    options,
  }: {
    instanceName: EventLoggerInstanceName
    apiKey: string
    userId: string
    options?: Record<string, unknown>
  }) {
    this.name = instanceName
    this.amplitude = amplitude.getInstance(instanceName)
    this.amplitude.init(apiKey, userId || null, options)
  }

  log(event: string, eventProperty?: Record<string, unknown>): void {
    const mergedEventProperty: { cohort: string } = {
      cohort: this.cohort,
      ...eventProperty,
    }

    this.amplitude.logEvent(event, mergedEventProperty)
  }

  configure(): void {
    this.setUserProperties()
  }

  private setUserProperties(): void {
    const userProperties = {
      'Device Type': getMobileOperatingSystem(),
    }
    this.userProperties = userProperties
    this.amplitude.setUserProperties(userProperties)
  }
}
