import React from 'react'

import { TComponentProps } from 'models/common.model'

import { StyledContainer as S } from './Container.styles'

export const Container: React.FC<TComponentProps> = ({
  children,
  className = '',
}) => <S.Container className={className}>{children}</S.Container>
