import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

import { MIN_PASSWORD_LENGTH } from 'root-constants'

export const usePasswordInputField = (): TInputFieldHook => {
  const { t } = useTranslation()
  const [password, setPassword] = useState<IInputFieldState>({
    value: '',
    isValid: true,
    isFocused: false,
    validationText: '',
  })

  useEffect(() => {
    if (password.value.length < MIN_PASSWORD_LENGTH && password.value !== '') {
      setPassword((prevState) => ({
        ...prevState,
        isValid: false,
      }))
      return
    }

    setPassword((prevState) => ({
      ...prevState,
      isValid: true,
    }))
  }, [password.value, t])

  useEffect(() => {
    setPassword((prevState) => ({
      ...prevState,
    }))
  }, [password.isValid, t])

  return [password, setPassword]
}
