import { AnyAction } from 'redux'

import {
  RESET_ERROR,
  SET_APP_CONFIG,
  SET_APP_NAME,
  SET_ERROR,
  SET_SOURCE,
  START_FETCHING,
  STOP_FETCHING,
} from 'root-redux/actions/common'

import { IAppConfig } from 'models/config.model'

const initState: ICommonState = {
  appName: '',
  isFetching: false,
  actionList: [],
  error: null,
  appConfig: null,
  source: '',
}

export interface ICommonState {
  appName: string
  isFetching: boolean
  actionList: string[]
  error: any
  appConfig: IAppConfig | null
  source: string
}

export function commonReducer(
  state: ICommonState = initState,
  { type, payload = null }: AnyAction,
): ICommonState {
  switch (type) {
    case SET_APP_NAME: {
      return {
        ...state,
        appName: payload,
      }
    }
    case START_FETCHING: {
      return {
        ...state,
        isFetching: true,
        actionList: state.actionList.concat(payload),
      }
    }
    case STOP_FETCHING: {
      return {
        ...state,
        isFetching: !!payload.length,
        actionList: payload,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case SET_APP_CONFIG: {
      return {
        ...state,
        appConfig: payload,
      }
    }
    case SET_SOURCE: {
      return {
        ...state,
        source: payload,
      }
    }
    default:
      return state
  }
}
