import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserContactEmail } from 'root-redux/selects/user'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

import { EMAIL_REGEXP } from 'root-constants'

export const useEmailInputField = (): TInputFieldHook => {
  const { t } = useTranslation()
  const currentEmail = useSelector(selectUserContactEmail)

  const [email, setEmail] = useState<IInputFieldState>({
    value: currentEmail,
    isValid: true,
    isFocused: false,
    validationText: '',
  })

  useEffect(() => {
    if (!EMAIL_REGEXP.test(email.value) && email.value !== '') {
      setEmail((prevState) => ({
        ...prevState,
        isValid: false,
      }))
      return
    }

    setEmail((prevState) => ({
      ...prevState,
      isValid: true,
    }))
  }, [email.value])

  useEffect(() => {
    setEmail((prevState) => ({
      ...prevState,
    }))
  }, [t, email.isValid, email.isFocused])

  return [email, setEmail]
}
