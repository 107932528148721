import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setAppNameAction } from 'root-redux/actions/common'

import { APP_NAMES } from 'root-constants'

export const useAppNameFromUrl = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    const sourceUrl = window.location.origin

    const appNameFromUrl =
      Object.keys(APP_NAMES).find((domain) => sourceUrl.includes(domain)) || ''

    dispatch(setAppNameAction(APP_NAMES[appNameFromUrl]) || APP_NAMES.luvly)
  }, [dispatch])
}
