import { useEffect } from 'react'

import { v4 as uuidv4 } from 'uuid'

export const useGiaAnalyticsUserId = (): void => {
  useEffect(() => {
    if (sessionStorage.getItem('giaUserId')) return

    const giaAnalyticsUserId = uuidv4()

    sessionStorage.setItem('giaUserId', giaAnalyticsUserId)
  }, [])
}
